import React, { useState, useEffect } from "react";
import { Layout } from "containers/Layout";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { Tab } from 'semantic-ui-react';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Tooltip } from 'react-tooltip';
import { toast } from "react-toastify";

import BreadCrumbs from "components/shared/BreadCrumbs/index";
import Image from "components/shared/Image";
import { RequestDetails } from "components/RequestDetails";
import { NoAccess } from "components/shared/NoAccess";
import { CommunicationTemplates } from "components/CommunicationTemplates";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import { ExpertsReceived } from "components/ExpertsReceived";
import { RequestCalls } from "components/RequestCalls";
import { ActionAlertWindow } from "components/shared/ActionAlertWindow";
import { SearchableField } from "components/shared/SearchableField";

import CloseIcon from "assets/images/close-icon.svg";
import RequestId from "assets/images/request-id.svg";
import RequestorIcon from 'assets/images/requestor-icon.svg';
import ChargeCode from "assets/images/charge-code.svg";
import RequestedOn from "assets/images/requested-on.svg";
import EditIcon from "assets/images/edit-icon.svg";

import { getUserProfilePictures } from "api/getUserProfilePictures";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';
import { getUserId } from "utils/auth/auth";
import { formatDate } from "utils/formatDate/formatDate";
import { getStaffId, initiateTabClickAnalytics, globalAdobeAnalytics } from '../../analytics.js';

import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults_actions";
import { getCommTemplatesAPIData } from 'redux/actions/communicationTemplates/communicationTemplates.actions';
import { getExpReceivedAPIData } from 'redux/actions/expertReceived/expertReceived.actions';
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import { getRequestDetailsAPIData } from "redux/actions/requestDetails/requestDetails.actions";
import { getCloseRequestAPIData } from "redux/actions/closeRequest/closeRequest.actions";

import { selectors as requestCallsSelectors } from 'redux/reducers/calls/calls_reducer';
import { selectors as requestDetailsSelector } from "redux/reducers/requestDetails/requestDetails_reducer";
import { selectors as commTemplateSelectors } from 'redux/reducers/communicationTemplates/communicationTemplates_reducer';
import { selectors as expReceivedSelectors } from 'redux/reducers/expertReceived/expertReceived_reducer';
import { selectors as closeRequestSelectors } from "redux/reducers/closeRequest/closeRequest_reducer";

import './RequestDetailsWrapper.scss';

export const RequestDetailsWrapper = () => {
    const dispatch = useDispatch();

    const queryData = queryString.parse(location.search)?.interviewId;
    const templateId = Number(queryString.parse(location.search)?.templateId);

    const reqDetailsData = useSelector(requestDetailsSelector?.getRequestDetailsResults);
    const reqUpdateDetailsData = useSelector(requestDetailsSelector?.getUpdateDetailsResult);
    const commTemplatesData = useSelector(commTemplateSelectors.getCommTemplatesData);
    const expReceivedData = useSelector(expReceivedSelectors.getExpReceivedData);
    const callsData = useSelector(requestCallsSelectors.getCallsData);
    const closeRequest = useSelector(closeRequestSelectors.getCloseRequestResults);

    const [staffIdLoaded, setStaffIdLoaded] = useState(false);
    const [count, setCount] = useState(0);
    const [getNoAccess, setNoAccess] = useState(false);
    const [filteredResults, setFilteredResults] = useState(null);
    const [expRecCount, setExpRecCount] = useState(0);
    const [callsCount, setCallsCount] = useState(0);
    const [hidecommTemplates, setHidecommTemplates] = useState(templateId ? false : true);
    const [hideExpert, setHideExpert] = useState(true);
    const [hideCall, setHideCall] = useState(true);
    const [requestorName, setRequestorName] = useState('');
    const [childPaths, setChildPaths] = useState([]);
    const [requestDetailsResp, setRequestDetailsResp] = useState(null);
    const [showEditBtnFlag, setShowEditBtnFlag] = useState(true);
    const [editData, setEditData] = useState({});
    const [confirmationWindow, setConfirmationWindow] = useState({ open: false });

    const [requestPaths, setRequestPaths] = useState([{
        active: false,
        pathname: '/',
        name: 'Home'
    },
    {
        isActive: true,
        pathname: '/requestDetails',
        name: 'Request - ' + queryData,
        active: true,
        children: childPaths
    },
    {
        active: true,
        pathname: '/abc',
        name: 'Request Detail Page'
    }
    ]);

    const [panes, setPanes] = useState([]);
    const [initialActiveIndex, setInitialActiveIndex] = useState(templateId ? 1 : 0);
    const [closeRequestChangeCode, setCloseRequestChangeCode] = useState(null);

    useEffect(() => {
        if (queryData) {
            dispatch(getRequestDetailsAPIData({ interviewID: queryData, type: 'display' }));
            dispatch(getRequestDetailsAPIData({ interviewID: queryData, type: 'update' }));
        }
    }, [queryData]);

    useEffect(() => {
        if (confirmationWindow.open) {
            setConfirmationWindow({ ...confirmationWindow, chargeCode: closeRequestChangeCode });
        }
    }, [closeRequestChangeCode]);

    useEffect(() => {
        if (closeRequestChangeCode && closeRequest) {
            setCloseRequestChangeCode(null);
            setConfirmationWindow({ open: false });
            toast.success(closeRequest);
            setTimeout(() => {
                window.location.href = '/';
            }, 500);
        }
    }, [closeRequest]);

    useEffect(() => {
        const handleLoad = () => {
            if (staffIdLoaded) {
                if (!sessionStorage.getItem('hasLoaded')) {
                    sessionStorage.setItem('hasLoaded', 'true');
                    sendToLocalStorage('hasDetailsLoaded', 'true');
                    globalAdobeAnalytics('EEH Request Details Page', 'EEH Home Page');
                } else {
                    globalAdobeAnalytics('EEH Request Details Page', 'EEH Request Details Page');
                }
            }
        };

        handleLoad();
    }, [staffIdLoaded]);

    useEffect(() => {
        setCount(commTemplatesData?.totalTemplates);
    }, [commTemplatesData]);

    useEffect(() => {
        setExpRecCount(expReceivedData?.profileCountTotal);
    }, [expReceivedData]);

    useEffect(() => {
        setCallsCount(callsData?.totalCalls);
    }, [callsData]);



    useEffect(() => {
        const fetchRequestorName = async (data) => {
            if (data?.caseTeamMembers) {
                const name = await handleRequestorName(data.caseTeamMembers);
                setRequestorName(name);
            }
        };
        if (reqDetailsData?.requestStatus) {
            if (reqDetailsData.reqType == 'display') {
                const reqDetailsAPIResponse = reqDetailsData;
                fetchRequestorName(reqDetailsData);
                setRequestDetailsResp(reqDetailsData);
                setCloseRequestChangeCode(reqDetailsAPIResponse.chargeCode);
                // handleURLTampering();
                const tabArray = [
                    { menuItem: 'Request Details', render: () => <Tab.Pane><RequestDetails reqData={reqDetailsAPIResponse} onViewAllClick={onTabChangeHandler} interviewId={queryData} /></Tab.Pane> }
                ];
                if (reqDetailsData.projectType.id == 1 || reqDetailsData.projectType.id == 2) {
                    tabArray.push({ menuItem: `Communication Templates - ${count}`, render: () => <Tab.Pane><CommunicationTemplates hidecommTemplatesData={hidecommTemplates} /></Tab.Pane> });
                }
                tabArray.push(
                    { menuItem: `Experts Received - ${expRecCount || 0}`, render: () => <Tab.Pane><ExpertsReceived hideExpRec={hideExpert} caseTeamMembers={reqDetailsData?.caseTeamMembers} /></Tab.Pane> },
                    { menuItem: `Calls - ${callsCount || 0}`, render: () => <Tab.Pane><RequestCalls hideCallRec={hideCall} caseTeamMembers={reqDetailsData?.caseTeamMembers} /></Tab.Pane> }
                );
                setPanes(tabArray);
            }
        }

    }, [reqDetailsData, hidecommTemplates, hideExpert, count, expRecCount, hideCall, callsCount]);

    useEffect(() => {
        if (reqUpdateDetailsData?.reqType == 'update') {
            setEditData(reqUpdateDetailsData);
        }
    }, [reqUpdateDetailsData]);

    useEffect(() => {
        handleURLTampering();
    }, [requestDetailsResp]);

    const handleURLTampering = async () => {
        const hrid = await getUserId();
        let filteredHrid = null;
        if (requestDetailsResp?.caseTeamMembers) {
            filteredHrid = await requestDetailsResp?.caseTeamMembers?.filter((item) => item?.hrId === hrid);
        }
        setFilteredResults(filteredHrid);
    };

    useEffect(() => {
        if (filteredResults === null) {
            return;
        }
        if (filteredResults.length === 0) {
            setNoAccess(true);
        } else {
            setNoAccess(false);
        }
    }, [filteredResults]);


    useEffect(() => {
        fetchUserDetails();
        fetchLoggedInUserEmail();
    }, []);

    const fetchUserDetails = async () => {
        const hrid = await getUserId();
        dispatch(getPeopleAPIData(hrid));
        let templateQueryData = {
            requestId: queryData,
            templateIds: templateId ? [templateId] : null,
            status: null,
            networkProviders: null,
            expertTypes: null,
        };
        dispatch(getCommTemplatesAPIData(templateQueryData, 0));
        let profileQueryData = {
            interviewRequestId: queryData,
            expertName: null,
            expertTypes: null,
            relevantCompanies: null,
            countries: null,
            networkProviders: null,
            costs: null,
            profileStatus: null,
            tags: []
        }
        dispatch(getExpReceivedAPIData(profileQueryData, 0));
        let callsQueryData = {
            requestId: queryData,
            expertName: null,
            expertTypes: null,
            relevantCompanies: null,
            status: null,
            networkProviders: null,
        }
        dispatch(getCallsAPIData(callsQueryData, 0));
    }

    const fetchLoggedInUserEmail = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
            setStaffIdLoaded(true);
        }
    }

    const handleRequestorName = async (caseTeam) => {
        let resp = [];
        let arr = [];
        let name = '';
        for (let i = 0; i < caseTeam?.length; i++) {
            if (caseTeam[i]?.memberType === 'requestor') {
                arr?.push(caseTeam[i]?.hrId);
            }
        }
        if (arr?.length > 0) {
            resp = await getUserProfilePictures(arr);
            if (resp?.length > 0) {
                name = resp[0]?.firstName?.concat(' ')?.concat(resp[0]?.lastName);
            }
        }
        return name;
    }

    const editRequestHandler= ()=>{
        initiateTabClickAnalytics('Edit Request Details Page', 'Edit Request Details', 'EEH Request Details Page', queryData);
    }

    const onTabChangeHandler = (event) => {
        let selectedTab = (event.target.text)?.toLowerCase();
        const tabPanesIndex = panes.findIndex((m) => m.menuItem == event.target.text);
        if (selectedTab?.includes('details')) {
            if (sessionStorage.getItem('hasLoaded')) {
                initiateTabClickAnalytics('EEH Request Details Page', 'Request Details', 'EEH Request Details Page');
            }
            else {
                initiateTabClickAnalytics('EEH Request Details Page', 'Request Details', 'EEH Home Page');
            }
        }
        else if (selectedTab?.includes('communication')) {
            initiateTabClickAnalytics('EEH Request Details Page', 'Communication Templates', 'EEH Request Details Page');
        }
        else if (selectedTab?.includes('received')) {
            initiateTabClickAnalytics('EEH Request Details Page', 'Experts Received', 'EEH Request Details Page');
        }
        else if (selectedTab?.includes('calls')) {
            initiateTabClickAnalytics('EEH Request Details Page', 'Calls', 'EEH Request Details Page');
        }
        if (selectedTab && selectedTab.includes('Received')) {
            setShowEditBtnFlag(false);
        } else {
            setShowEditBtnFlag(true);
        }
        if (tabPanesIndex > -1) {
            if (selectedTab?.includes('expert')) {
                setHideExpert(false);
            }
            if (selectedTab?.includes('communication')) {
                setHidecommTemplates(false);
            }
            if (selectedTab?.includes('calls')) {
                setHideCall(false);
            }
            setInitialActiveIndex(tabPanesIndex);
        } else {
            if (selectedTab?.includes('expert')) {
                setHideExpert(false);
                setInitialActiveIndex(2);
            }
            if (selectedTab?.includes('communication')) {
                setHidecommTemplates(false);
                setInitialActiveIndex(1);
            }
            if (selectedTab?.includes('request')) {
                setInitialActiveIndex(0);
            }
            if (selectedTab?.includes('calls')) {
                setHideCall(false);
                setInitialActiveIndex(3);
            }
        }
    }

    const onCloseRequestHandler = (e) => {
        if (!closeRequestChangeCode) {
            setConfirmationWindow({
                open: true,
                showCloseIcon: true,
                popupWidth: 'auto',
                popupHeight: 'auto',
                heading: 'Charge Code Missing',
                details: 'You haven’t provided a charge code for this request. Please enter a valid charge code below. If you still don’t have a charge code, you may proceed with scheduling but have to provide it before you close the request.',
                cancelActionTxt: 'Cancel',
                proceedActionTxt: 'Proceed',
                noInputFields: false
            });
        } else {
            dispatch(getCloseRequestAPIData({ requestId: queryData, chargeCode: '' }));
        }
    }

    const closeAlertWindow = (flag, chargeCode) => {
        if (flag) {
            setConfirmationWindow({ open: false });
        }
        if (closeRequestChangeCode) {
            if (!flag) {
                dispatch(getCloseRequestAPIData({ requestId: queryData, chargeCode: closeRequestChangeCode }));
            } else {
                setCloseRequestChangeCode(null);
            }
        }
    }

    return (
        <>
            {confirmationWindow?.open &&
                <ActionAlertWindow props={confirmationWindow} closeAlertWindowHandler={(flag) => closeAlertWindow(flag)}>
                    <SearchableField
                        fieldId="closedField"
                        preferredListHeight='95px'
                        isValid={true}
                        isDisable={false}
                        savedValue=''
                        validateErrors={() => console.log('error')}
                        onChargeCodeSelected={(value, field) => setCloseRequestChangeCode(value)}
                        isSurveyForm={false}
                    />
                </ActionAlertWindow>
            }
            <Layout id="chat" layoutClass="layout__mynavigator">
                <div className="requestDetails__wrapper">
                    {
                        (getNoAccess === true && requestDetailsResp) ?
                            <NoAccess />
                            :
                            <>
                                <div className="requestDetails__pagehead">
                                    <BreadCrumbs paths={requestPaths} />
                                    <div className="details__buttonWrapper">
                                        {
                                            requestDetailsResp &&
                                            <button className="close--request" disabled={requestDetailsResp.requestStatus == 'Closed'} onClick={(e) => onCloseRequestHandler(e)}><Image src={CloseIcon} alt="CloseIcon" className="close--icon" />Close Request</button>
                                        }
                                    </div>
                                </div>
                                <div className="requestDetails__data">
                                    {requestDetailsResp?.requestTitle?.length > 250 && <Tooltip anchorSelect=".tooltip-title" place="bottom-start" effect='solid' multiline={true} className="custom-tooltip tooltip-width">{requestDetailsResp?.requestTitle}</Tooltip>}
                                    <a className="tooltip-title">
                                        <div className="details--title">
                                            {requestDetailsResp?.requestTitle ? requestDetailsResp.requestTitle : <ImageParaSkeleton showPara={true} paraCount={1} size="medium" height={4} />}
                                        </div>
                                    </a>
                                    <div className="details__data">
                                        <div className="details--status">
                                            Status : {requestDetailsResp?.requestStatus ? requestDetailsResp?.requestStatus : <ImageParaSkeleton showPara={true} paraCount={1} size="extraSmall" height={4} />}
                                        </div>
                                        <div className="details--item">
                                            <Image src={RequestId} alt="RequestId" className="item-img" />
                                            <div className="item--text">Request - ID : #{requestDetailsResp?.interviewRequestId ? requestDetailsResp?.interviewRequestId : <ImageParaSkeleton showPara={true} paraCount={1} size="extraSmall" height={4} />}</div>
                                        </div>

                                        <div className="details--item">
                                            <Image src={ChargeCode} alt="ChargeCode" className="item-img" />
                                            <div className="item--text">Charge Code : {requestDetailsResp?.chargeCode ? requestDetailsResp?.chargeCode : 'NA'}</div>
                                        </div>

                                        <div className="details--item">
                                            <Image src={RequestedOn} alt="RequestedOn" className="item-img" />
                                            <div className="item--text">Requested On : {requestDetailsResp?.requestCreationDate ? formatDate(requestDetailsResp?.requestCreationDate) : <ImageParaSkeleton showPara={true} paraCount={1} size="extraSmall" height={4} />}</div>
                                        </div>

                                        <div className="details--item">
                                            <Image src={RequestorIcon} alt="RequestorIcon" className="item-img" />
                                            <div className="item--text">Requestor : {requestorName ? requestorName : <ImageParaSkeleton showPara={true} paraCount={1} size="extraSmall" height={4} />}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="requestDetails__body">
                                    {
                                        filteredResults?.length > 0 &&
                                        <div className="requestDetails__tabsbody">
                                            <Tab panes={panes} onTabChange={(event) => onTabChangeHandler(event)} activeIndex={initialActiveIndex} />
                                            {showEditBtnFlag && <div className="edit__request">
                                                {requestDetailsResp.requestStatus == 'Closed' ?
                                                    <div className="disabled-link">
                                                        <Image src={EditIcon} alt="EditIcon" className="edit--icon" />
                                                        <span>Edit Request Details</span>
                                                    </div> :
                                                    <Link to={{
                                                        pathname: '/ExpertRequest/edit',
                                                        state: { ...editData, id: queryData }
                                                    }}
                                                    onClick={() => editRequestHandler()}
                                                    >
                                                        <Image src={EditIcon} alt="EditIcon" className="edit--icon" />
                                                        Edit Request Details
                                                    </Link>}
                                            </div>}
                                        </div>
                                    }
                                </div>
                            </>
                    }
                </div>
            </Layout>
        </>
    )
}