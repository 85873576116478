import React, { useEffect, useState } from "react";
import "./ExpertProfile.scss";
import { Popup } from 'semantic-ui-react';
import moment from 'moment';
import Modal from "components/shared/Modal";
import { Image } from "semantic-ui-react";
import ShowMoreIcon from 'assets/images/show-more.svg';
import GreenCrossIcon from 'assets/images/close-popup.svg';
import ShowLessIcon from 'assets/images/show-less.svg';
import CrossIcon from "assets/images/cross-large.svg";
import RecommendIcon from "assets/images/recommended-icon.svg";
import MinusIcon from "assets/images/minus-outline.svg";
import StarIcon from "assets/images/star-outline.svg";
import FilledStarIcon from "assets/images/star.svg";
import PinIcon from "assets/images/location-pin.svg";
import UserIcon from "assets/images/user-icon.svg";
import CalIcon from "assets/images/blue_calender_icon.svg";
import SlackIcon from "assets/images/slack-icon.svg";
import ClockIcon from "assets/images/clock-outline.svg";
import MoreInfoIcon from "assets/images/info-orange.svg";
import NextArrow from "assets/images/arrow-right.svg";
import PrevArrow from "assets/images/arrow-left.svg";
import { Tooltip } from 'react-tooltip';
import { DataDisplayGrid } from "components/shared/DataDisplayGrid/DataDisplayGrid";
import { setExpertProfileActionsAPIData } from "redux/actions/expertProfileActions/expertProfileActions.actions";
import { selectors as profileActionSelector } from "redux/reducers/expertProfileActions/expertProfileActions_reducer";
import { useDispatch, useSelector } from "react-redux";
import './TooltipWithTail.scss';

export const ExpertProfile = ({ open, data, selectedIndex, lastRecord, selectedRecord, closePopupHandler, carouselActionClicked, openScheduler, handleDuplicateProfilesClick, duplicateProfiles, refreshProfileHandler }) => {
    // const networkPopupRef = useRef(null);    Kirti: may required in future.
    const dispatch = useDispatch();

    const profileActionStatus = useSelector(profileActionSelector.getSaveProfileActionsResults);

    const empColumns = [
        { name: 'Employer', value: 'employer' },
        { name: 'Title', value: 'title' },
        { name: 'Tenure', value: 'tenure' },
        { name: 'Home Office', value: 'homeOffice' }
    ];
    const expColumns = [
        { name: 'Employer', value: 'employer' },
        { name: 'Title', value: 'title' },
        { name: 'Start Date', value: 'startDate' },
        { name: 'End Date', value: 'endDate' },
        { name: 'Tenure', value: 'tenure' },
        { name: 'Home Office', value: 'homeOffice' }
    ];

    const [expData, setExpData] = useState([]);
    const [empData, setEmpData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [declinedReason, setDeclinedReason] = useState('');
    const [openModel, setOpenModel] = useState(open);
    const [showExpOffLimit, setShowExpOffLimit] = useState(false);
    // kirti: may required in future
    // const [isNetworkPopupOpen, setIsNetworkPopupOpen] = useState(false);
    // const [selectedNetwork, setSelectedNetwork] = useState('');
    // const [externalNetworkData, setExternalNetworkData] = useState();
    const [profileSelected, setSelectedProfile] = useState(0);
    const [actionMadeFlag, setActionMadeFlag] = useState(true);
    const [duplicateCount, setDuplicateCount] = useState([]);
    const [getBiography, setBiography] = useState(data.biography?.length > 350 ? data.biography?.substring(0, 350) : data.biography);

    const handleOffLimitShowMore = () => {
        setShowExpOffLimit(!showExpOffLimit);
        if (!showExpOffLimit) {
            setBiography(data?.biography);
        }
        else {
            setBiography(data?.biography?.substring(0, 350))
        }
    }

    useEffect(() => {
        if (data?.profileStatus === 'New' && data?.userViewed === false) {
            dispatch(setExpertProfileActionsAPIData({ "expertProfileId": data.id, "action": "view" }));
        }
        setBiography(data.biography?.length > 350 ? data.biography?.substring(0, 350) : data.biography);
        data.biography?.length > 350 ? setShowExpOffLimit(false) : setShowExpOffLimit(true);
    }, [data]);

    useEffect(() => {
        if (data) {
            if (data.relevantExperience) {
                setExpData((prevData) => {
                    let dataItems = [...prevData];
                    const tenureAvailable = ((data.relevantExperience.tenureYear != null && data.relevantExperience.tenureYear > -1) && data.relevantExperience.tenureMonth != null && data.relevantExperience.tenureMonth > -1)
                    let relevantExperience = {
                        employer: data.relevantExperience.employerName,
                        title: data.relevantExperience.title,
                        startDate: moment().set('month', data.relevantExperience?.startMonth - 1 || '')?.format('MMM') + ' ' + data?.relevantExperience?.startYear || '',
                        endDate: data.relevantExperience.employerStatus == "Current" ? 'Present' : moment().set('month', data.relevantExperience?.endMonth - 1 || '')?.format('MMM') + ' ' + data?.relevantExperience?.endYear || '',
                        tenure: tenureAvailable ? data.relevantExperience.tenureYear + ' yrs ' + data.relevantExperience.tenureMonth + ' mo' : '',
                        homeOffice: data.relevantExperience.homeOffice
                    };
                    dataItems = [relevantExperience];
                    return dataItems;
                });
            }
            if (data.employmentHistory?.length > 0) {
                setEmpData(() => {
                    let dataItems = [];
                    data.employmentHistory.forEach((emp, index) => {
                        let empStart = moment().set('month', emp.startMonth - 1 || '')?.format('MMM') + ' ' + emp.startYear || '',
                            empEnd = emp.currentEmployment ? 'Present' : moment().set('month', emp.endMonth - 1 || '')?.format('MMM') + ' ' + emp.endYear || '',
                            yrGap = emp.endYear - emp.startYear,
                            monthGap = emp.endMonth > emp.startMonth ? emp.endMonth - emp.startMonth : ((12 - emp.startMonth) + emp.endMonth);
                        if (monthGap > 11) {
                            yrGap = yrGap + 1;
                            monthGap = monthGap - 12;
                        }
                        let empTotal = '';
                        if (!emp.currentEmployment) {
                            empTotal = monthGap > 0 ? '(' + yrGap + '.' + monthGap + ' yrs)' : '(' + yrGap + ' yrs)';
                        }
                        dataItems.push({
                            employer: emp.employerName,
                            title: emp.title,
                            tenure: empStart + ' - ' + empEnd + '<br/> ' + empTotal,
                            homeOffice: emp.homeOffice
                        });
                    });
                    return dataItems;
                });
            }
        }
    }, [data]);

    useEffect(() => {
        if (duplicateProfiles?.length > 0) {
            setDuplicateCount(duplicateProfiles);
        }
    }, [duplicateProfiles]);

    useEffect(() => {
        setSelectedProfile(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        if (profileActionStatus?.message && !actionMadeFlag) {
            setActionMadeFlag(true);
            refreshProfileHandler();
        }
    }, [profileActionStatus]);

    // kirti: may required in future
    // useEffect(() => {
    //     if (externalNetworkData?.length > 0) {
    //         setSelectedNetwork(externalNetworkData[0]);
    //     }
    // }, [externalNetworkData]);

    // kirti: may required in future
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (networkPopupRef.current && !networkPopupRef.current.contains(event.target)) {
    //             setIsNetworkPopupOpen(false);
    //         }
    //     };

    //     const handleMouseDown = (event) => {
    //         // Check if the down arrow image was clicked
    //         // if (!event.target.classList.contains('arrow-img') && !event.target.classList.contains('dropdown--textbox')) {
    //         handleClickOutside(event);
    //     };
    //     document.addEventListener("mousedown", handleMouseDown);

    //     return () => {
    //         document.removeEventListener("mousedown", handleMouseDown);
    //     };
    // }, []);

    const profileActionHandler = (type) => {
        setActionMadeFlag(false);
        if (type === 'decline') {
            dispatch(setExpertProfileActionsAPIData({ "expertProfileIds": [data.id], "action": type, "comment": declinedReason }));
        }
        else {
            dispatch(setExpertProfileActionsAPIData({ "expertProfileIds": [data.id], "action": type }));
        }
    };
    return (
        <>
            <Modal isOpen={openModel} className="expertprofile__modal">
                <div>
                    <button className='carousel-btn' onClick={(event) => carouselActionClicked('previous', profileSelected)} disabled={profileSelected == 0}>
                        <Image src={PrevArrow} className='edit--icon' alt="previous icon" />
                        <span className='disable-btn'>Previous</span>
                    </button>
                </div>
                <div className="expertprofile__main-div">
                    <div className="expertprofile__wrapper model-wrapper">
                        <div className="model-header">
                            <div className="expertprofile__right-title">
                                {duplicateCount?.length &&
                                    <div className="duplicate-text" onClick={() => {
                                        setOpenModel(false);
                                        handleDuplicateProfilesClick(duplicateCount);
                                    }}>View Duplicates ({duplicateCount.length})</div>
                                }
                                <img className="close-icon" src={CrossIcon} alt="close" onClick={() => {
                                    setOpenModel(false);
                                    closePopupHandler();
                                }} />
                            </div>
                            <div className="expertprofile__title">Profile</div>
                            <div className="expertprofile__heading">
                                <div className="line1">
                                    <div className="expert-info">
                                        {data?.expertName}
                                        {data.recommended && <Image src={RecommendIcon} alt="recommended icon" />}
                                    </div>
                                    <div className="action-btns">

                                        {/* <button
                                            className="decline-btn"
                                            onClick={() => { profileActionHandler('decline') }}
                                            disabled={data.profileStatus == "Declined"}>
                                            {data?.profileStatus === 'Declined' ?
                                                <>
                                                    <Image src={MinusIcon} alt="star icon" /> Declined
                                                </>
                                                :
                                                <>
                                                    <Image src={MinusIcon} alt="star icon" /> Decline
                                                </>
                                            }
                                        </button> */}
                                        <div style={{ position: 'relative' }}>
                                            <Popup

                                                content={
                                                    <>
                                                        <div className="tooltip--headWrap">
                                                            <div className='tooltip--head'>Reason for declining <span className="tooltip--optional">(Optional)</span></div>
                                                            <img className="green-icon" src={GreenCrossIcon} alt="close"
                                                             onClick={() => {
                                                                setVisible(false); setDeclinedReason('')
                                                            }}
                                                            />
                                                        </div>
                                                        <input type='text' placeholder="Reason for declining" className="decline--input" onChange={(e) => setDeclinedReason(e?.target?.value)} />
                                                        <div className="tooltip--btns">
                                                            {/* <button onClick={() => { setVisible(false); setDeclinedReason('') }} className='outline-btn'>Skip</button> */}
                                                            <button onClick={() => { profileActionHandler('decline'); setVisible(false); }} className="default-button">Submit</button>
                                                        </div>
                                                    </>
                                                }
                                                trigger={
                                                    <button
                                                        className="decline-btn"
                                                        onClick={() => { setVisible(true); }}
                                                        disabled={data.profileStatus === "Declined"}
                                                    >
                                                        {data?.profileStatus === 'Declined' ? (
                                                            <>
                                                                <Image src={MinusIcon} alt="star icon" /> Declined
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Image src={MinusIcon} alt="star icon" /> Decline
                                                            </>
                                                        )}
                                                    </button>
                                                }
                                                on="click"
                                                position="bottom right"
                                                open={visible}
                                                onClose={() => setVisible(false)}
                                                className="popup-tooltip"
                                            />
                                        </div>
                                        <button
                                            className="shortlist-btn"
                                            onClick={() => data?.shortlisted ? profileActionHandler('unshortlist') : profileActionHandler('shortlist')}>
                                            {data.shortlisted ?
                                                <>
                                                    <Image src={FilledStarIcon} alt="star icon" /> Shortlisted
                                                </>
                                                :
                                                <>
                                                    <Image src={StarIcon} alt="star icon" /> Shortlist
                                                </>
                                            }
                                        </button>
                                    </div>
                                </div>

                                <div className="line2">
                                    <div className="line2__sub">
                                        <div className="line2__child">
                                            {data?.relevantExperience.title} - {data?.relevantExperience.employerName}
                                            <span className="separator">&nbsp;|</span>
                                            <div className="expert-location">
                                                <Image src={PinIcon} alt="location pin" />
                                                {data?.country}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="line3">
                                    <div className="line3__sub">
                                        <div className="profile-icons"><Image src={UserIcon} alt='user icon' /></div>
                                        <div className="line3__sub1">
                                            <div className="line3__sub11">External Network:</div>
                                            <div className="line3__sub12">{data?.networkProvider}</div>
                                        </div>
                                    </div>
                                    {/* <div className={`mini-dropdown ${isNetworkPopupOpen && 'field-dropdown'}`}>  kirti: may required in future
                                <input type='text' readOnly value={selectedNetwork}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIsNetworkPopupOpen(!isNetworkPopupOpen);
                                    }}
                                ></input>
                                <img className="arrow-img" src={DownArrow} alt="DownArrow"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIsNetworkPopupOpen(!isNetworkPopupOpen);
                                    }} />
                                {
                                    isNetworkPopupOpen &&
                                    <div className="mini-dropdown-popup" ref={networkPopupRef}>
                                        <ul>
                                            {externalNetworkData?.map((option, popupIndex) => (
                                                <li key={popupIndex} onClick={(event) => { setSelectedNetwork(option); setIsNetworkPopupOpen(false); }} className={selectedNetwork == option ? 'selected-list-item' : ''}>{option}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div> */}
                                    <div className="line3__sub">
                                        {data.rate && <div className="line3__sub1"><div className="line3_sub1"><div className="profile-icons"><span>$</span></div>
                                            <span>${data.rate.costPerHour}/hr, ${data.rate.costPer30Mins}/30 min</span>
                                        </div>
                                            <div className="line3_sub1 normal-text">({data.rate.rateType})
                                            </div></div>}
                                    </div>
                                    <div className="line3__sub">
                                        <div className="profile-icons"><Image src={CalIcon} alt='calender Icon' /></div>
                                        <div className="line3__sub1">
                                            <div className="line3__sub11">Profile Sent On:</div>
                                            <div className="line3__sub12">{moment(data.receivedTimestamp).format('ddd, DD MMM YYYY LT')} GMT</div>
                                        </div>
                                        {/* <span>Profile Sent On : {moment(data.receivedTimestamp).format('ddd, DD MMM YYYY LT')} GMT</span> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="expertprofile__details model-body">
                            <span>About Expert</span>
                            <p>
                                {getBiography}
                            </p>
                            {
                                data.biography?.length > 350 &&
                                <div className="show--more" onClick={() => handleOffLimitShowMore()}>
                                    <Image src={showExpOffLimit ? ShowLessIcon : ShowMoreIcon} alt="ShowMoreIcon" className="showMore--img" />
                                    {showExpOffLimit ? 'Show Less' : 'Show More'}
                                </div>

                            }
                            {data.expertTypes?.length > 0 && <>
                                <span>Expert Types / Modules</span>
                                <div className="expert-type-list">
                                    {data.expertTypes.map((type, index) => (
                                        <div key={type} className="expert-type"><span>{type}</span></div>
                                    ))}
                                </div>
                            </>
                            }
                            <span>Relevant work experience </span>
                            <DataDisplayGrid columns={expColumns} data={expData} />
                            {data.screeningQuestions?.length > 0 && <>
                                <span>Responses to screening questions</span>
                                {data.screeningQuestions.map((set, index) => (
                                    <div className='screening-ques' key={set.question}>
                                        <div className="qus-ans-block">
                                            <div className="qus-icon"><span>Q</span></div>
                                            <div className="question"><span>{set.question}</span></div>
                                        </div>
                                        <div className="qus-ans-block">
                                            <div className="qus-icon ans-icon"><span>A</span></div>
                                            <div className="answer"><span>{set.answer}</span></div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            }
                            <span>Employment History</span>
                            <DataDisplayGrid columns={empColumns} data={empData} showTenure={true} />
                        </div>
                        <div className="expertprofile__action-container model-footer">
                            <button className='outline-btn' onClick={(event) => { setOpenModel(false); closePopupHandler(); }}>
                                <Image src={SlackIcon} className='edit--icon' alt="slack" />
                                <span className='disable-btn'>Request more information</span>
                            </button>
                            {data?.status != 'Approved' ?
                                <Tooltip anchorSelect=".tooltip-request-call" place="bottom-start" effect='solid' multiline={true} content="Calls cannot be requested at this time as the communication template for this external network has not yet been approved. Please check back later or contact the network for more details." />
                                : <Tooltip anchorSelect=".tooltip-request-call" place="bottom-start" effect='solid' multiline={true} content="Click to propose or select from available time slots suggested by experts." />}
                            <button className="default-button tooltip-request-call" onClick={() => { setOpenModel(false); closePopupHandler(); openScheduler(); }}
                                disabled={data?.requestCallAllowed !== true} >
                                <Image src={ClockIcon} className='edit--icon' alt="clock" />
                                <span>Request Call</span>
                            </button>
                            <Tooltip anchorSelect=".info-icon" place="bottom-start" effect='solid' multiline={true}>Click “Request Call” to propose or select from available time slots suggested by experts.</Tooltip>
                            <Image className='edit--icon info-icon' src={MoreInfoIcon} alt="info icon" />
                        </div>
                    </div>
                </div>
                <div>
                    <button className='carousel-btn' onClick={(event) => { carouselActionClicked('next', profileSelected); }} disabled={lastRecord}>
                        <span className='disable-btn'>Next</span>
                        <Image src={NextArrow} className='edit--icon' alt="next icon" />
                    </button>
                </div>
            </Modal>
        </>
    );
};