import React, { useEffect, useState } from "react";
import "./ScheduleCall.scss";
import Modal from "components/shared/Modal";
import moment from 'moment-timezone';
import { DropdownField } from "components/shared/DropdownField";
import { Checkbox, Image } from "semantic-ui-react";
import { delay, msToTime } from "utils/helpers/helpers";
import CalIcon from "assets/images/blue_calender_icon.svg";
import ClockIcon from "assets/images/clock-icon.svg";
import InputClockIcon from "assets/images/clock-icon-green.svg";
import CONFIG from "config";
import authService from 'redux/auth';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import PlusIcon from 'assets/images/plus-large.svg';
import { DatePickerField } from "components/DatePickerField";
import CrossIcon from "assets/images/cross-large.svg";
import { setSaveCallSlotsAPIData } from "redux/actions/saveCallSlots/saveCallSlots.actions";
import { selectors as saveCallSelectors } from "redux/reducers/saveCallSlots/saveCallSlots_reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PeopleSelector } from "@kdshared/peopleselector";
import timezones from "timezones.json";

export const ScheduleCall = ({ open, data, caseTeamMembers, requestId, closePopupHandler, isRescheduleWindowFlag, isFollowUpCallFlag }) => {
    const dispatch = useDispatch();
    const durationList = [
        { name: '15 mins', value: 15 },
        { name: '30 mins', value: 30 },
        { name: '60 mins', value: 60 },
        { name: '90 mins', value: 90 }
    ];

    const callScheduled = useSelector(saveCallSelectors.getSaveRequestResults);

    const [openModel, setOpenModel] = useState(open);
    const [accessToken, setAccessToken] = useState([]);
    const [expertSectionOpen, setExpertSectionOpen] = useState(false);
    const [userSectionOpen, setUserSectionOpen] = useState(false);
    const [userSlotData, setUserSlotData] = useState([]);
    const [expertSlotData, setExpertSlotData] = useState([]);
    const [membersList, setMembersList] = useState([]);
    const [attendeeList, setAttendeeList] = useState([]);
    const [scheduleCallFlag, setScheduleCallFlag] = useState(false);
    const [peoplePrefilledList, setPeoplePrefilledList] = useState([]);
    const [userTimezone, setUserTimezone] = useState('Asia/Kolkata');
    const [disableSendBtn, setDisableSendBtnFlag] = useState(true);
    const [timeOptions, setTimeOptions] = useState({
        timeZone: userTimezone,
        hour: '2-digit',
        minute: '2-digit',
    });
    const [userSelection, setUserSelection] = useState({
        checked: true,
        date: '',
        duration: 60,
        durationDisplay: '60 mins',
        datetime: '',
        startTime: '',
        endTime: '',
        timezone: 'IST'
    });
    const timeZoneArray = [];

    // Fetch the user timezone
    const getUserTimeZone = async () => {
        try {
            const hrid = await getUserId();
            const resp = await getUserProfilePictures([hrid]);

            if (resp?.length > 0 && resp[0]?.hostOfficeLocation?.timezone) {
                setUserTimezone(resp[0].hostOfficeLocation.timezone);
                setTimeOptions({...timeOptions, timeZone:resp[0].hostOfficeLocation.timezone});
                const timezoneDetails = timezones.find((tz) => tz.utc.includes(resp[0].hostOfficeLocation.timezone));
                if (timezoneDetails) {
                    setUserSelection((selection) => {
                        const updatedList = { ...selection };
                        updatedList['timezone'] = timezoneDetails.abbr;
                        return updatedList;
                    });
                }
            }
        } catch (error) {
            setUserTimezone('Error fetching timezone');
        }
    };

    useEffect(() => {
        getUserTimeZone();
    }, []);

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
        setUserSelection((selection) => {
            const updatedList = { ...selection };
            updatedList['timezone'] = moment().tz(moment.tz.guess()).format('z');
            return updatedList;
        });

        return () => {
            setScheduleCallFlag(false);
        };
    }, []);

    useEffect(() => {
        if (scheduleCallFlag && callScheduled) {
            if (callScheduled.status?.toLowerCase().includes('success')) {
                toast.success('The slots have been successfully shared with the networks.');
                setOpenModel(false);
                closePopupHandler(true);
            } else if (callScheduled.error?.errorDetails) {
                toast.error(callScheduled.error?.errorDetails);
            }
        }
    }, [callScheduled]);

    useEffect(() => {
        if (caseTeamMembers?.length > 0) {
            handleRequestorName(caseTeamMembers);
        }
    }, [caseTeamMembers]);

    useEffect(() => {
        if (isRescheduleWindowFlag) {
            if (data.duration) {
                setUserSelection((selection) => {
                    const updatedList = { ...selection };
                    updatedList['duration'] = data.duration;
                    updatedList['durationDisplay'] = data.duration + ' mins';

                    return updatedList;
                });
            }
            if (data.selectedSlots) {
                const date = new Date(data.selectedSlots.startDateTime).toLocaleDateString();
                const localStartDateTime = new Date(data.selectedSlots?.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true });
                const localEndDateTime = new Date(data.selectedSlots?.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true });
                const localStartDateTime1 = new Date(data.selectedSlots?.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });
                const localEndDateTime1 = new Date(data.selectedSlots?.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });

                if (!data.selectedSlots.isExpertSlot) {
                    // setUserSectionOpen(true);
                    setUserSlotData(() => {
                        let updatedData = [];
                        const selectionData = getTimeInterval(localStartDateTime1, localEndDateTime1);
                        const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == date);
                        if (duplicateSlotRecord > -1) {
                            const newTimeSlot = selectionData.timeInterval;
                            if (updatedData[duplicateSlotRecord].time.findIndex((t) => t.slot == newTimeSlot) < 0) {
                                updatedData[duplicateSlotRecord].time.push({
                                    checked: false,
                                    disable: true,
                                    slot: newTimeSlot,
                                    startTime: localStartDateTime,
                                    endTime: localEndDateTime
                                });
                                updatedData[duplicateSlotRecord].checked = true;
                            } else {
                                toast.info('Time already added.');
                            }
                        } else {
                            const newSelectionDetails = {
                                date: date,
                                day: getDay(new Date(data.selectedSlots.startDateTime)),
                                checked: false,
                                disable: true,
                                time: [{
                                    checked: false,
                                    disable: true,
                                    slot: selectionData.timeInterval,
                                    startTime: localStartDateTime,
                                    endTime: localEndDateTime
                                }],
                                timezone: userTimezone
                            };
                            updatedData.push(newSelectionDetails);
                        }

                        return updatedData;
                    });
                }
                //  else {
                //     setExpertSectionOpen(true);
                // }
            }
        }
        if (data.availableSlots?.length > 0) {
            data.availableSlots.forEach((avlSlot) => {
                const date = new Date(avlSlot.startDateTime);
                const selectedDateValue = date.toLocaleDateString();
                const slotDiff = msToTime(new Date(avlSlot.endDateTime).getTime() - new Date(avlSlot.startDateTime).getTime(), 'diff');

                const localStartDateTime = new Date(avlSlot?.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true });
                const localEndDateTime = new Date(avlSlot?.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true });

                const localStartDateTime1 = new Date(avlSlot?.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });
                const localEndDateTime1 = new Date(avlSlot?.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });

                setExpertSlotData((expertData) => {
                    let updatedData = [...expertData];
                    if (avlSlot.isExpertSlot) { //need to modify after BE changes
                        const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                        const selectedSlotRecord = data.selectedSlots ? new Date(data.selectedSlots.startDateTime).toLocaleDateString() == selectedDateValue && data.selectedSlots.isExpertSlot : false;
                        if (duplicateSlotRecord > -1) {
                            const oldTimeSlot = updatedData[duplicateSlotRecord].displayTimeString;
                            const newTimeSlot = slotDiff + ' (' + localStartDateTime + ' ' + ' - ' + localEndDateTime + ')';
                            updatedData[duplicateSlotRecord].displayTimeString = oldTimeSlot + ' , ' + newTimeSlot;
                            updatedData[duplicateSlotRecord].startTime.push(localStartDateTime1);
                            updatedData[duplicateSlotRecord].endTime.push(localEndDateTime1);

                        } else {
                            const newSelectionDetails = {
                                date: selectedDateValue,
                                dateValue: selectedDateValue,
                                day: getDay(date),
                                userStartTime: '',
                                startTime: [localStartDateTime1],
                                endTime: [localEndDateTime1],
                                checked: false,
                                displayTimeString: 'Available Slots : ' + slotDiff + ' (' + localStartDateTime + ' ' + ' - ' + localEndDateTime + ')',
                                timezone: userSelection.timezone
                            };

                            if (selectedSlotRecord) {
                                const selectedSlotDiff = msToTime(new Date(data.selectedSlots.endDateTime).getTime() - new Date(data.selectedSlots.startDateTime).getTime(), 'diff'),
                                    selectedStartDateTime = new Date(data.selectedSlots?.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true }),
                                    selectedEndDateTime = new Date(data.selectedSlots?.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: true });
                                newSelectionDetails.selectedTimeString = 'Scheduled Slot : ' + selectedSlotDiff + ' (' + selectedStartDateTime + ' ' + ' - ' + selectedEndDateTime + ')';
                                newSelectionDetails.selectedStartTime = new Date(data.selectedSlots.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });
                                newSelectionDetails.selectedEndTime = new Date(data.selectedSlots.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false });
                            }
                            updatedData.push(newSelectionDetails);
                        }
                    }
                    return updatedData;
                });
            });
        }
    }, [data]);

    const handleRequestorName = async (caseTeam) => {
        let resp = [];
        let arr = [];
        for (let i = 0; i < caseTeam?.length; i++) {
            arr?.push(caseTeam[i]?.hrId);
        }
        setPeoplePrefilledList(arr);
        if (arr?.length > 0) {
            resp = await getUserProfilePictures(arr);
            setMembersList((member) => {
                let updatedList = [...member];
                if (resp?.length > 0) {
                    resp.forEach((record) => {
                        updatedList.push({ name: record?.lastName?.concat(', ').concat(record?.firstName), email: record.email, hrid: record.id });
                    });
                }
                return updatedList;
            });
        }
    }

    const formatHrsMinsString = (dataString) => {
        const requestString = dataString.length == 1 ? '0' + dataString : dataString;
        return requestString;
    }

    const getTimeInterval = (start, end) => {
        const starttimeArray = start.split(':'), endtimeArray = end.split(':');

        const hh = Number(starttimeArray[0].trim()) > 12 ? Number(starttimeArray[0].trim()) - 12 : Number(starttimeArray[0].trim());
        const mm = starttimeArray[1];

        const endMM = endtimeArray[1];
        const endHH = Number(endtimeArray[0].trim()) > 12 ? Number(endtimeArray[0].trim()) - 12 : Number(endtimeArray[0].trim());

        let endTimePeriod = (Number(endtimeArray[0].trim()) > 12 || Number(endtimeArray[0].trim()) == 12) ? 'PM' : 'AM',
            startTimePeriod = (Number(starttimeArray[0].trim()) > 12 || Number(starttimeArray[0].trim()) == 12) ? 'PM' : 'AM';

        const startHrs = formatHrsMinsString(hh.toString());
        const startMins = formatHrsMinsString(mm.toString());
        const endHrs = formatHrsMinsString(endHH.toString());
        const endMins = formatHrsMinsString(endMM.toString());

        if (endHrs == '00') {
            endTimePeriod = 'AM';
        }
        if (startHrs == '00') {
            startTimePeriod = 'AM';
        }

        const startTimeString = startHrs == '00' ? '12:' + startMins : startHrs + ':' + startMins;
        const endTimeString = endHrs == '00' ? '12:' + endMins : endHrs + ':' + endMins;

        const timeInterval = endTimePeriod == startTimePeriod ? startTimeString + ' - ' + endTimeString + ' ' + endTimePeriod : startTimeString + ' ' + startTimePeriod + ' - ' + endTimeString + ' ' + endTimePeriod;

        return { timeInterval };
    }

    const getDay = (date) => {
        const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let selectedDay = weekday[date.getDay()];
        return selectedDay;
    }

    const addSlotHandler = () => {
        if (!userSelection.timeError) {
            if (userSelection.date && userSelection.startTime && userSelection.endTime && userSelection.timezone) {
                setUserSectionOpen(true);
                const selectedDate = new Date(userSelection.date);
                const selectedDateValue = selectedDate.toLocaleString().split(',')[0];
                setUserSlotData((userData) => {
                    let updatedData = [...userData];
                    const selectionData = getTimeInterval(userSelection.startTime, userSelection.endTime);
                    if (!userSelection.timeError) {
                        const duplicateSlotRecord = updatedData.findIndex((slot) => slot.date == selectedDateValue);
                        if (duplicateSlotRecord > -1) {
                            const newTimeSlot = selectionData.timeInterval;
                            if (updatedData[duplicateSlotRecord].time.findIndex((t) => t.slot == newTimeSlot) < 0) {
                                updatedData[duplicateSlotRecord].time.push({
                                    checked: true,
                                    slot: newTimeSlot,
                                    duration: userSelection.duration,
                                    startTime: userSelection.startTime,
                                    endTime: userSelection.endTime
                                });
                                updatedData[duplicateSlotRecord].checked = true;
                                setDisableSendBtnFlag(false);
                            } else {
                                toast.info('Time already added.');
                            }
                        } else {
                            const newSelectionDetails = {
                                date: selectedDateValue,
                                day: getDay(selectedDate),
                                checked: true,
                                time: [{
                                    checked: true,
                                    slot: selectionData.timeInterval,
                                    duration: userSelection.duration,
                                    startTime: userSelection.startTime,
                                    endTime: userSelection.endTime
                                }],
                                timezone: userSelection.timezone
                            };
                            setDisableSendBtnFlag(false);
                            updatedData.push(newSelectionDetails);
                        }
                    }
                    return updatedData;
                });
            } else {
                toast.error("Some values are missing.");
            }
        }
    }

    const handleSlotTimeSelection = (slotType, date, time) => {
        if (slotType == 'user') {
            let checkCount = 0;
            setUserSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot) => {
                    let otherSelectedTimeIndex = -1;
                    if (slot.date == date) {
                        const timeIndex = slot.time.findIndex((t) => t.slot == time.slot);
                        if (timeIndex > -1) {
                            slot.checked = !slot.time[timeIndex].checked;
                            slot.time[timeIndex].checked = !slot.time[timeIndex].checked;
                        }
                        otherSelectedTimeIndex = slot.time.findIndex((t) => t.checked);
                    }
                    if (slot.checked || otherSelectedTimeIndex > -1) {
                        checkCount = checkCount + 1;
                    }
                });
                setDisableSendBtnFlag(slotUpdatedList.length == 0);
                return slotUpdatedList;
            });
            if(checkCount==0){
                setDisableSendBtnFlag(true);
            }
            setUserSectionOpen(checkCount > 0 ? true : false);
        } else {
            let checkedCount = 0;
            setExpertSlotData((slotData) => {
                const slotUpdatedList = [...slotData];
                slotUpdatedList.forEach((slot) => {
                    if (slot.date == date) {
                        slot.showTimeSelection = !slot.checked;
                        slot.checked = !slot.checked;
                    }
                    if (slot.checked) {
                        checkedCount = checkedCount + 1;
                        setDisableSendBtnFlag(!slot.userStartTime);
                    } else {
                        slot.userStartTime = '';
                    }
                    slot.userTimeFieldType = slot.userStartTime ? 'time' : 'text';
                    slot.error = slot.userStartTime ? slot.error : false;
                });
                return slotUpdatedList;
            });
            if(checkedCount==0){
                setDisableSendBtnFlag(true);
            }
            setExpertSectionOpen(checkedCount > 0 ? true : false);
        }
    }

    const extractSelectedSlotsData = () => {
        const slotData = userSectionOpen ? userSlotData : expertSlotData;
        let selectedData = [];
        slotData.forEach((slot) => {
            if (userSectionOpen) {
                const anySlotSelected = slot.time.filter((t) => t.checked);
                if (anySlotSelected?.length > 0) {
                    anySlotSelected.forEach((us) => {
                        if (us.endTime && us.startTime) {
                            let availableDate = moment(slot.date, "MM-DD-YYYY").format('YYYY-MM-DD');
                            let formatedData = {
                                "endDateTime": new Date(availableDate + ' ' + us.endTime).toISOString(),
                                "startDateTime": new Date(availableDate + ' ' + us.startTime).toISOString(),
                                "isExpertSlot": !userSectionOpen
                            };
                            selectedData.push(formatedData);
                        }
                    })
                }
            } else {
                if (slot.checked) {
                    if (slot.userEndTime && slot.userStartTime && !slot.error) {
                        let slotDate = moment(slot.dateValue, "MM-DD-YYYY").format('YYYY-MM-DD');
                        let formatedData = {
                            "endDateTime": new Date(slotDate + ' ' + slot.userEndTime).toISOString(),
                            "startDateTime": new Date(slotDate + ' ' + slot.userStartTime).toISOString(),
                            "isExpertSlot": !userSectionOpen
                        };
                        selectedData.push(formatedData);
                    }
                }
            }
        });
        return selectedData;
    }

    const handleExpertSlotSelection = (event) => {
        let isValidTime = true;
        setExpertSlotData(() => {
            const slotUpdatedList = [...expertSlotData];
            const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == event.target.id);
            slotUpdatedList[selectedRecordIndex].userStartTime = event.target.value;
            slotUpdatedList[selectedRecordIndex].duration = userSelection.duration;
            if (event.target.value?.includes(':')) {
                let timeArray = event.target.value.split(':');
                slotUpdatedList[selectedRecordIndex].userEndTime = getCallEndDate(timeArray[0], timeArray[1], Number(userSelection.duration));
                isValidTime = validateSelectedCallSlot(event.target.value, slotUpdatedList[selectedRecordIndex].userEndTime, slotUpdatedList[selectedRecordIndex].startTime, slotUpdatedList[selectedRecordIndex].endTime);
                let isSelectedTimeValid = true;
                if (data?.status?.toLowerCase() == 'upcoming') {
                    if (event.target.value == slotUpdatedList[selectedRecordIndex]?.selectedStartTime &&
                        slotUpdatedList[selectedRecordIndex].userEndTime == slotUpdatedList[selectedRecordIndex].selectedEndTime) {
                        isSelectedTimeValid = false;
                    }
                }

                if (!isValidTime) {
                    slotUpdatedList[selectedRecordIndex].error = "Please select valid start time/end time.";
                } else if (!isSelectedTimeValid) {
                    slotUpdatedList[selectedRecordIndex].error = "Slot already selected.";
                } else {
                    slotUpdatedList[selectedRecordIndex].error = false;
                }
                setDisableSendBtnFlag(!isValidTime || !isSelectedTimeValid);
            } else {
                slotUpdatedList[selectedRecordIndex].userEndTime = '';
                slotUpdatedList[selectedRecordIndex].error = false;
            }
            return slotUpdatedList;
        });
    }

    const validateSelectedCallSlot = (startTime, endTime, startSlots, endSlots) => {
        let flag = false, validSlotCount = 0;
        const startTimeValue = Number(startTime?.replace(/:/g, ''));
        const endTimeValue = Number(endTime?.replace(/:/g, ''));
        startSlots.forEach((item, index) => {
            let availStartTime = Number(item.replace(/:/g, ''));
            let availEndTime = Number(endSlots[index]?.replace(/:/g, ''));

            const sameTimePeriodFlag = (availStartTime < availEndTime && (startTimeValue == availStartTime || startTimeValue > availStartTime) && (endTimeValue < availEndTime || endTimeValue == availEndTime));
            const diffTimePeriodFlag = (availStartTime > availEndTime && (startTimeValue == availStartTime || startTimeValue > availStartTime || (startTimeValue < availStartTime && startTimeValue < availEndTime)) && (endTimeValue == availEndTime || endTimeValue < availEndTime || (endTimeValue > availEndTime && endTimeValue < 2500 && endTimeValue > availStartTime)));
            if (sameTimePeriodFlag || diffTimePeriodFlag) {
                validSlotCount = validSlotCount + 1;
            }
        });
        if (validSlotCount > 0) {
            flag = true;
        }
        return flag;
    }

    const getCallEndDate = (hours, minutes, duration) => {
        let durationMins = 0, durationHrs = Number(hours);
        if (duration > 59) {
            durationMins = duration - 60;
            durationHrs = durationHrs + 1;
        } else {
            durationMins = duration;
        }
        if (minutes > 0) {
            durationMins = durationMins + Number(minutes);
            if (durationMins > 59) {
                durationMins = durationMins - 60;
                durationHrs = durationHrs + 1;
            }
        }
        if (String(durationMins).length == 1) {
            durationMins = '0' + String(durationMins);
        }

        if (String(durationHrs).length == 1) {
            durationHrs = '0' + String(durationHrs);
        }

        return durationHrs + ':' + durationMins;
    }

    const sendSlotsHandler = () => {
        const attendeeData = mergedMembers();
        const slotsSelectedData = extractSelectedSlotsData();
        if (attendeeData?.length > 0 && slotsSelectedData?.length > 0) {
            const requestData = {
                "requestId": requestId,
                "projectId": data.projectId,
                "isReschedule": isRescheduleWindowFlag ? true : false,
                "expertProfile": {
                    "id": data.id,
                    "profileId": data.profileId,
                    "networkProvider": data.networkProvider
                },
                "followUpCall": isFollowUpCallFlag ? true : false,
                "duration": userSelection.duration,
                "reason": null,
                "selectedSlots": slotsSelectedData,
                "attendees": attendeeData
            };
            if(isRescheduleWindowFlag){
                requestData['id'] = data.callId;
            }
            setScheduleCallFlag(true);
            dispatch(setSaveCallSlotsAPIData({ data: requestData }));
        } else {
            toast.error('Either attendee list or call slots were not selected.')
        }
    }

    const mergedMembers = () => {
        let updatedList = [];
        if (attendeeList?.length > 0) {
            updatedList = [...attendeeList];
        }
        else if (membersList?.length > 0) {
            membersList.forEach((item) => {
                updatedList.push({
                    "attendeeEmail": item.email,
                    "attendeeHrId": item.hrid
                });
            });
        }
        return updatedList;
    }

    const updateAttendeeSelection = (e) => {
        if (e.selectionArray && e.selectionArray.length > 0) {
            let memberSelected = e.selectionArray;
            setAttendeeList(() => {
                const updatedMemberList = [];
                memberSelected.forEach((select) => {
                    const newData = {
                        "attendeeEmail": select.email ? select.email : select.workEmail,
                        "attendeeHrId": select.id
                    };
                    updatedMemberList.push(newData);
                });
                return updatedMemberList;
            });
        }
    }

    const durationSelactionHandler = (selected) => {
        setExpertSlotData((slotData) => {
            const slotUpdatedList = [...slotData];
            slotUpdatedList.forEach((slot) => {
                if (slot.checked) {
                    slot.showTimeSelection = !slot.checked;
                    slot.checked = !slot.checked;
                }
                if (!slot.disable) {
                    slot.userStartTime = '';
                    slot.userTimeFieldType = 'text';
                }
                slot.error = false;
            });
            return slotUpdatedList;
        });
        setExpertSectionOpen(false);
        setUserSectionOpen(false);
        if (isRescheduleWindowFlag) {
            setUserSlotData(() => {
                let updatedData = [];
                userSlotData.forEach((slot) => {
                    if (slot.time?.length > 0) {
                        const keepSlot = { ...slot, time: [] };
                        slot.time.forEach((t) => {
                            if (t.disable) {
                                keepSlot.time.push(t);
                            }
                        });
                        if (keepSlot.time?.length > 0) {
                            updatedData.push(keepSlot);
                        }
                    }
                });
                return updatedData;
            });
        } else {
            setUserSlotData([]);
        }
        setUserSelection((selection) => {
            const updatedList = { ...selection };
            updatedList['duration'] = selected.slice(0, 2);
            updatedList['durationDisplay'] = selected;
            updatedList['startTime'] = '';
            updatedList['endTime'] = '';
            updatedList['timeError'] = '';

            return updatedList;
        });
    }

    const onCalOpenHandler = async (event) => {
        await delay(300);
        const popupContent = document.getElementById('modelBody');
        popupContent.scrollTop = popupContent.scrollHeight;
    }

    return (
        <Modal isOpen={openModel} className="schedulecall__modal">
            <div className="schedulecall__wrapper model-wrapper">
                <div className="model-header">
                    <img className="close-icon" src={CrossIcon} alt="close" onClick={() => { setOpenModel(false); closePopupHandler(false); }} />
                    <div className="schedulecall__heading">
                        <span>Schedule Call with {data?.expertName}</span>
                    </div>
                </div>
                <div className="model-body" id="modelBody">
                    <div className="schedulecall__common-block">
                        <div className="time-timezone">
                            <div className="step-label">
                                <span className="step-count">Step 1 :</span> Select Call duration and time zone <span className="mandatory">*</span>
                            </div>
                            <div className='time-field expert-time-field right-margin'>
                                <DropdownField data={durationList} fieldIndex='0' name='duration' fieldId='duration' placeholderTxt='Select duration'
                                    selectedValue={userSelection.durationDisplay}
                                    onSelectHandler={(selected) => durationSelactionHandler(selected)}
                                    onFocusOutHandler={() => { return true; }} />

                            </div>
                            <div className='time-field expert-time-field left-margin'>
                                <DropdownField data={timeZoneArray} fieldIndex='0' name='time' fieldId='time'
                                    selectedValue={userSelection.timezone}
                                    isDisabled={true}
                                    onSelectHandler={(selected) => {
                                        setUserSelection((selection) => {
                                            const updatedList = { ...selection };
                                            updatedList['timezone'] = selected;
                                            return updatedList;
                                        });
                                    }}
                                    onFocusOutHandler={() => { return true; }} />

                            </div>
                        </div>
                        <div className="meeting-invites">
                            <div className="step-label">
                                <span className="step-count">Step 2 :</span> Choose case team member(s) to be added  to the Invite
                            </div>
                            <PeopleSelector
                                label=''
                                name="originalRequestor"
                                apiKey={CONFIG.PEOPLE_API_X_API_KEY}
                                accessToken={accessToken}
                                environment='preproduction-internal'
                                fieldHeight="48px"
                                preFillData={[peoplePrefilledList]}
                                onChange={(e) => updateAttendeeSelection(e)}
                            />
                        </div>
                    </div>
                    <div className="schedulecall__details">
                        <div className="expert-slots">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 3 :</span> Choose a slot from expert&lsquo;s availability
                            </div>
                            <div className={`slots-available ${userSectionOpen && 'disable-section'}`}>
                                {expertSlotData?.length > 0 &&
                                    expertSlotData.map((card, index) => (
                                        <div key={index} className={`slot-card ${index != expertSlotData.length - 1 && 'div-bottom-border'}`}>
                                            <div className="level1 slot-details">
                                                <div className="slot-date">
                                                    <Checkbox className="een-checkbox" checked={card.checked} disabled={userSectionOpen} onChange={() => handleSlotTimeSelection('expert', card.date)}></Checkbox>
                                                    &nbsp;&nbsp;{card.date} &nbsp;
                                                    <div className="slot-day other-details">{'(' + card.day + ')'}</div>
                                                </div>
                                                <div className="slot-data other-details"><div className="blank-div"></div>{card.displayTimeString}</div>
                                                <div className="slot-data other-details"><div className="blank-div"></div>{card.selectedTimeString}</div>

                                                {card.showTimeSelection &&
                                                    <div className='start-time-expert-field'>
                                                        <label htmlFor='usertime' className="form-label">Start Time</label>
                                                        <div>
                                                            <input type={card.userTimeFieldType}
                                                                id={card.date}
                                                                name={'expertTime' + index}
                                                                placeholder='HH : MM AM/PM'
                                                                value={card.userStartTime}
                                                                className="input-fields form-control"
                                                                onFocus={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = 'time';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }
                                                                }
                                                                onBlur={() => {
                                                                    setExpertSlotData((slotData) => {
                                                                        const slotUpdatedList = [...slotData];
                                                                        const selectedRecordIndex = slotUpdatedList.findIndex((e) => e.date == card.date);
                                                                        slotUpdatedList[selectedRecordIndex].userTimeFieldType = slotUpdatedList[selectedRecordIndex].userStartTime ? 'time' : 'text';
                                                                        return slotUpdatedList;
                                                                    })
                                                                }}
                                                                onChange={(event) => handleExpertSlotSelection(event)}
                                                            />
                                                            <Image className='expert-time-img' src={InputClockIcon} alt="clock icon" />
                                                        </div>
                                                        <div className="help-txt"><span>Please enter a desired start time for the provided time slots.</span></div>
                                                        {card.error && <div className="error-txt">{card.error}</div>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                            </div>

                        </div>
                        <div className="user-slots-section">
                            <div className="step-label slot-labels">
                                <span className="step-count">Step 4 :</span> If expert&lsquo;s availability is not provided or suitable for you, share your availability with networks
                            </div>
                            <div className="slots-available">
                                <div className="user-slots">
                                    <div className='time-field user-date-field right-margin'>
                                        <label htmlFor='userDate' className="form-label">Date</label>
                                        <DatePickerField
                                            fieldId='userDate'
                                            minDateValue={new Date()}
                                            isDisabled={expertSectionOpen}
                                            savedValue={userSelection.date}
                                            onDateSelectHandler={(value) =>
                                                setUserSelection((selection) => {
                                                    const updatedList = { ...selection };
                                                    updatedList['date'] = value;
                                                    return updatedList;
                                                })}
                                            onCalOpen={(event) => onCalOpenHandler(event)}
                                            onCalClose={(event) => { console.log('scheduler calender closed', event); }} />
                                    </div>
                                    <div className='time-field user-time-field'>
                                        <label htmlFor='userstarttime' className="form-label">From</label>
                                        <div className="time-input-field">
                                            <input
                                                type={userSelection.startTime ? 'time' : 'text'} id='userstarttime' name='userstarttime' disabled={expertSectionOpen} placeholder='HH : MM AM/PM' value={userSelection.startTime} className="input-fields form-control"
                                                onFocus={(e) => e.target.type = 'time'}
                                                onBlur={(e) => {
                                                    if (!userSelection.startTime) {
                                                        e.target.type = 'text';
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    setUserSelection((selection) => {
                                                        const updatedList = { ...selection };
                                                        updatedList['startTime'] = event.target.value;
                                                        if (event.target.value?.includes(':') && updatedList['endTime']) {
                                                            const startTimeValue = event.target.value.replace(':', '');
                                                            const endTimeValue = updatedList['endTime'].replace(':', '');
                                                            const userSlotDiff = ((Number(endTimeValue.slice(0, 2)) - Number(startTimeValue.slice(0, 2))) * 60) + (Number(endTimeValue.slice(2, endTimeValue.length)) - Number(startTimeValue.slice(2, startTimeValue.length)));
                                                            if (endTimeValue < startTimeValue || userSlotDiff < updatedList.duration) {
                                                                updatedList['timeError'] = endTimeValue < startTimeValue ? 'End time should be greater than start time.' : 'Time slot should be greater than duration selected.';
                                                            } else {
                                                                updatedList['timeError'] = '';
                                                            }
                                                        }
                                                        return updatedList;
                                                    });
                                                }
                                                }
                                            />
                                            <Image className='user-time-img' src={InputClockIcon} alt="clock icon" />
                                        </div>
                                    </div>
                                    <div className='time-field user-time-field'>
                                        <label htmlFor='userendtime' className="form-label">Till</label>
                                        <div className="time-input-field">
                                            <input type={userSelection.endTime ? 'time' : 'text'} id='userendtime' name='userendtime' disabled={expertSectionOpen} placeholder='HH : MM AM/PM' value={userSelection.endTime} className="input-fields form-control"
                                                min={userSelection.startTime}
                                                onFocus={(e) => e.target.type = 'time'}
                                                onBlur={(e) => {
                                                    if (!userSelection.endTime) {
                                                        e.target.type = 'text';
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    setUserSelection((selection) => {
                                                        const updatedList = { ...selection };
                                                        updatedList['endTime'] = event.target.value;
                                                        if (event.target.value?.includes(':') && updatedList['startTime']) {
                                                            const startTimeValue = updatedList['startTime'].replace(':', '');
                                                            const endTimeValue = event.target.value.replace(':', '');
                                                            const userSlotDiff = ((Number(endTimeValue.slice(0, 2)) - Number(startTimeValue.slice(0, 2))) * 60) + (Number(endTimeValue.slice(2, endTimeValue.length)) - Number(startTimeValue.slice(2, startTimeValue.length)));
                                                            let selectedSlotRecord = false;
                                                            if (data.selectedSlots) {
                                                                const scheduleDate = new Date(data.selectedSlots.startDateTime).toLocaleDateString();
                                                                const startTime = new Date(data.selectedSlots.startDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false }).replace(':', '');
                                                                const endTime = new Date(data.selectedSlots.endDateTime)?.toLocaleTimeString('en-US', { ...timeOptions, hour12: false }).replace(':', '');
                                                                if(new Date(updatedList.date).toLocaleDateString() == scheduleDate){
                                                                    selectedSlotRecord = startTime == startTimeValue && endTime == endTimeValue;
                                                                }
                                                            }
                                                            if (selectedSlotRecord) {
                                                                updatedList['timeError'] = 'Slot already selected.';
                                                            }
                                                            else if (endTimeValue < startTimeValue || userSlotDiff < updatedList.duration) {
                                                                updatedList['timeError'] = endTimeValue < startTimeValue ? 'End time should be greater than start time.' : 'Time slot should be greater than duration selected.';
                                                            } else {
                                                                updatedList['timeError'] = '';
                                                            }
                                                        }
                                                        return updatedList;
                                                    });
                                                }
                                                }
                                            />
                                            <Image className='user-time-img' src={InputClockIcon} alt="clock icon" />
                                        </div>
                                    </div>
                                </div>
                                {userSelection.timeError && <div className="error-txt time-error"><div className="blank-block"></div><span>{userSelection.timeError}</span></div>}
                                <div className='slot-button-section'>
                                    <button className="add-slot" disabled={expertSectionOpen} onClick={(e) => { e.preventDefault(); addSlotHandler(); }}>
                                        <Image src={PlusIcon} alt='plus icon' />
                                        Add Slot
                                    </button>
                                </div>
                                {userSlotData.length > 0 &&
                                    <div className={`${expertSectionOpen && 'disable-section'}`}>
                                        <span className="selected-slots-header">Selected Slots</span>
                                        {userSlotData.map((slot, index) => (
                                            <div key={index} className={`slot-card ${index != userSlotData.length - 1 && 'slot-border'}`}>
                                                <div className="level1 slot-details">
                                                    <div className="slot-date"> <Image src={CalIcon} alt="calender icon" />{slot.date}</div>
                                                    <div className="slot-day other-details"><div className="blank-div"></div>{slot.day}</div>
                                                    <div className="slot-time other-details">
                                                        {slot.time.map((t, i) => (
                                                            <>
                                                                {/* {t.disable && <span className="selected-slots-header">Scheduled Slots</span>} */}
                                                                <div key={i} className="one-slot-block">
                                                                    <Checkbox className="een-checkbox" checked={t.checked} disabled={expertSectionOpen || t.disable} onChange={() => handleSlotTimeSelection('user', slot.date, t)}></Checkbox>
                                                                    <Image src={ClockIcon} alt="clock icon" />{t.slot}
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="schedulecall__action-container model-footer">
                    <button className="default-button" onClick={() => { sendSlotsHandler() }} disabled={disableSendBtn}>Send Slots to Network</button>
                </div>
            </div>
        </Modal>
    );
};