import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import OutlookIcon from "assets/images/outlook-icon.svg";
import LocationIcon from "assets/images/location-icon.svg";
import Image from "components/shared/Image";
import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults_actions";
import { selectors as peopleResultsSelector } from "redux/reducers/peopleresults/peopleresults_reducer";
import { getRandomColor } from "utils/helpers/helpers";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getAuthorStaffId, initiateAuthorProfileClickAnalytics } from '../../analytics.js';
import './TeamMembers.scss';

export const TeamMembers = ({ data, templatePage }) => {
    const dispatch = useDispatch();
    const peopleResults = useSelector(peopleResultsSelector?.getPeopleResults);
    const loading = useSelector(peopleResultsSelector?.getLoading);
    const [caseTeamData, setCaseTeamData] = useState(null);
    const [noCaseTeamMember, setNoCaseTeamMember] = useState(false);
    const [getLoading, setLoading] = useState(true);

    useEffect(() => {
        let hridArr = [];
        if (data?.length > 0) {
            for (let i = 0; i < data?.length; i++) {
                // if (data[i]?.memberType !== 'requestor')
                hridArr?.push(data[i]?.hrId);
            }
            dispatch(getPeopleAPIData(hridArr));
        }

        let tempArr = data;
        let filtertedArr = tempArr;//tempArr?.filter((item) => item?.memberType === 'case_team_member');
        if (filtertedArr?.length === 0) {
            setNoCaseTeamMember(true);
        }
        else {
            setNoCaseTeamMember(false);
        }
    }, [data]);

    useEffect(() => {
        if (peopleResults[0]) {
            if (peopleResults[0]?.length > 1) {
                const respData = peopleResults[0];
                respData.forEach((ele) => {
                    ele.picture = ele?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : ele.picture;
                    ele.nameInitials = ele?.firstName.charAt(0) + ele?.lastName.charAt(0);
                    ele.bgColor = getRandomColor();
                });

                setCaseTeamData(respData);
            } else {
                let requesterData = peopleResults[0] && peopleResults[0][0]?.picture ? peopleResults[0][0] : peopleResults[0];
                if (requesterData?.picture) {
                    requesterData.picture = requesterData?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : requesterData.picture;
                    if (requesterData?.firstName && requesterData?.lastName) {
                        requesterData.nameInitials = requesterData?.firstName?.charAt(0) + requesterData?.lastName?.charAt(0);
                    } else {
                        const nameArray = requesterData?.preferredName ? requesterData?.preferredName.split(', ') : [];
                        let userInitials = '';
                        if (nameArray.length > 0) {
                            userInitials = nameArray.length > 1 ? nameArray[1].charAt(0) + nameArray[0].charAt(0) : nameArray[0].charAt(0);
                        }
                        requesterData.nameInitials = userInitials;
                    }

                    requesterData.bgColor = getRandomColor();
                    let tempArray = [];
                    tempArray.push(requesterData);
                    setCaseTeamData(tempArray);
                }
            }
        }
    }, [peopleResults]);

    useEffect(() => {
        setLoading(loading)
    }, [loading]);

    const handleProfileRedirection = async (hrid) => {
        const resp = await getUserProfilePictures([hrid]);
        // if (resp?.length > 0) {
        //     getAuthorStaffId(resp[0]?.email);
        // }
        initiateAuthorProfileClickAnalytics('EEH Request Details Page', 'Request Details', resp[0]?.email, 'EEH Request Details Page');
        window?.open(`https://profiles-stg.navigator.bcg.com/overview?hrid=${hrid}`);
    }

    const displayUserCurrentTime = (timeZone) => {
        let date = new Date();

        // Use the toLocaleTimeString() method to display only the hour and minute in the specified time zone
        const options = {
            timeZone: timeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        return date.toLocaleTimeString("en-US", options);

    }
    return (
        <>
            <div className={!templatePage ? "teamMembers__wrapper" : "teamMembers__wrapper templatePage__wrapper"}>
                {
                    !templatePage &&
                    <div className="teamMembers__headWrap">
                        <div className="teamMembers__leftheadWrap">
                            <div className="team--headText">
                                Case Team Members
                            </div>
                            <div className={"team--count"}>
                                {data?.length}
                            </div>
                        </div>
                    </div>
                }

                <div className={!templatePage ? "teamMembers__bodyWrap" : "teamMembers__bodyWrap templatePage__bodywrapper"}>
                    {
                        caseTeamData?.length > 0 && noCaseTeamMember === false
                            ?
                            <>
                                {

                                    caseTeamData?.map((item, index) =>
                                        <div className="teamMembers__body" key={index} onClick={() => handleProfileRedirection(item?.id)}>
                                            <div className="teamMembers__bodyTop">
                                                <div className="user--profile">
                                                    {item?.picture ?
                                                        <Image src={item?.picture} alt="profile NP" className='user--img' /> :
                                                        <span
                                                            style={{ background: item.bgColor }}
                                                            className={`user--img custom--profile--pic`}>
                                                            {item.nameInitials}
                                                        </span>}
                                                </div>
                                                <div className="user__body">
                                                    <div className="body--name">{item?.firstName?.concat(' ')?.concat(item?.lastName)}</div>
                                                    <div className="body--designation">{item?.jobTitle}</div>
                                                </div>
                                            </div>

                                            <div className="teamMembers__bodyBottom">
                                                <div className="outlook__wrap">
                                                    <Image src={OutlookIcon} alt="OutlookIcon" className='outlook--img' />
                                                </div>
                                                <div className="user__location">
                                                    <Image src={LocationIcon} alt="LocationIcon" className='location--img' />
                                                    <span className="location--text">{item?.homeOffice} - {displayUserCurrentTime(item?.hostOfficeLocation?.timezone)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                            :
                            (
                                (noCaseTeamMember === true && !templatePage) ?
                                    <div className="nores__found">
                                        <div className="nocalls__data">
                                            <div className="nocalls--head">
                                                No case team members added.
                                            </div>
                                            <div className="nocalls--text">
                                                Currently, there are no case team members associated with this project. Please click on &rsquo;Edit Project Details&rsquo; at the top of the page to add case team members and enhance collaboration.
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            (!templatePage && getLoading === true) &&
                                            <>
                                                <div className="teamMembers__body">
                                                    <div className="teamMembers__bodyTop">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                    <div className="teamMembers__bodyBottom">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                </div>

                                                <div className="teamMembers__body">
                                                    <div className="teamMembers__bodyTop">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                    <div className="teamMembers__bodyBottom">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                </div>

                                                <div className="teamMembers__body">
                                                    <div className="teamMembers__bodyTop">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                    <div className="teamMembers__bodyBottom">
                                                        <ImageParaSkeleton showPara={true} paraCount={2} size="mediumsmall" height={4} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>

                            )
                    }

                </div>
            </div>
        </>
    )
}