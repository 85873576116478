import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Dropdown, Checkbox } from 'semantic-ui-react';
import Image from "components/shared/Image";
import { formatDate } from "utils/formatDate/formatDate";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import SortIcon from "assets/images/sort-icon.svg";
import SortAsc from "assets/images/sort-asc.svg";
import SortDesc from "assets/images/sort-desc.svg";
import RightIcon from "assets/images/right-icon.svg";
import WarningIcon from "assets/images/warning.svg";
import TopIcon from "assets/images/top-arrow.svg";
import FilterIcon from "assets/images/filter_icon.svg";
import CrossIcon from "assets/images/filter-close.svg";
import WhiteCrossIcon from "assets/images/white-cross.svg";
import CollapseIcon from "assets/images/collapse-icon.svg";
import ExpandIcon from "assets/images/expand-icon.svg";
import ApproveCheck from "assets/images/approve-check.svg";
import ShareTemplateIcon from 'assets/images/shareTemplate.svg';
import ApproveTemplateIcon from 'assets/images/approveTemplate.svg';
import { Template } from 'components/Template';
import { getCommTemplatesAPIData } from 'redux/actions/communicationTemplates/communicationTemplates.actions';
import { clearApproveTemplatesAPIData, getApproveTemplatesAPIData } from 'redux/actions/approveTemplates/approveTemplates.actions';
import { getTemplatesAPIData } from 'redux/actions/templateFilters/templateFilters.actions';
import { selectors as templateFiltersSelectors } from 'redux/reducers/templateFilters/templateFilters_reducer';
import { selectors as shareViaEmailSelector } from 'redux/reducers/shareViaEmail/shareViaEmail_reducer';
import { selectors as approveTemplatesSelectors } from 'redux/reducers/approveTemplates/approveTemplates_reducer';
import { selectors as commTemplateSelectors } from 'redux/reducers/communicationTemplates/communicationTemplates_reducer';
import { selectors as empDesignationSelectors } from 'redux/reducers/employeeDesignation/employeeDesignation_reducer';
import { selectors as editTemplateSelectors } from 'redux/reducers/editTemplate/editTemplate_reducer';
import { resetShareViaEmailAPIData } from 'redux/actions/shareViaEmail/shareViaEmail.actions';
import queryString from 'query-string';
import './CommunicationTemplates.scss';
import { CustomTooltip } from 'components/shared/CustomTooltip/CustomTooltip';
import { toast } from 'react-toastify';
import { ActionAlertWindow } from 'components/shared/ActionAlertWindow';

export const CommunicationTemplates = ({ hidecommTemplatesData }) => {
    const dispatch = useDispatch();
    const approverRef = useRef();
    const templateFiltersData = useSelector(templateFiltersSelectors?.getTemplateFitersResults);
    const commTemplatesData = useSelector(commTemplateSelectors.getCommTemplatesData);
    const empDesignation = useSelector(empDesignationSelectors.getDesignation);
    const editTemplateData = useSelector(editTemplateSelectors.getEditTemplateData);
    const error = useSelector(approveTemplatesSelectors?.getErrorMessage);
    const approveTemplatesData = useSelector(approveTemplatesSelectors.getApproveTemplatesData);
    const noRecFound = useSelector(commTemplateSelectors?.getNoRecordFound);
    const loading = useSelector(commTemplateSelectors?.getLoading);
    const [approverDesignation, setapproverDesignation] = useState('');
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [sortState, setSortState] = useState(null);
    const shareViaEmailSelectorData = useSelector(shareViaEmailSelector?.getshareEmailApprovalData);
    const shareEmailApprovalLoadingData = useSelector(shareViaEmailSelector?.getLoading);

    useEffect(() => {
        if (shareViaEmailSelectorData?.successfulNetworks?.length > 0) {
            toast.success('Approver email has been sent successfully');
            dispatch(resetShareViaEmailAPIData());
        }
        else if (shareViaEmailSelectorData?.failedNetworks?.length > 0) {
            toast.error('Error in sending approver email');
            dispatch(resetShareViaEmailAPIData());
        }
        handleCancel();
    }, [shareViaEmailSelectorData]);

    useEffect(() => {
        setApprovalLoading(shareEmailApprovalLoadingData);
    }, [shareEmailApprovalLoadingData]);

    useEffect(() => {
        setapproverDesignation(empDesignation);
    }, [empDesignation]);

    const [filters, setFilters] = useState({
        'status': [],
        'networkProviders': [],
        'expertTypes': []
    });
    const [filteredData, setFilteredData] = useState([]);
    const [showApprovePopup, setShowApprovePopup] = useState({ open: false });
    const [showApproveClicked, setApproveClicked] = useState(false);
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(true);
    const [expandedRows, setExpandedRows] = useState(data?.map(item => item?.templateId));
    const [expandedData, setExpandedData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [selectedAllApprover, setSelectedAllApprover] = useState('');
    const [showCrossIcon, setShowCrossIcon] = useState({
        'networkProviders': false,
        'expertTypes': false,
        'status': false
    });
    const [networkProvidersFilters, setNetworkProvidersFilters] = useState([]);
    const [expertTypesFilters, setExpertTypesFilters] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [showApproveAll, setShowApproveAll] = useState(false);
    const queryData = queryString.parse(location.search)?.interviewId;
    const queryTemplateId = Number(queryString.parse(location.search)?.templateId);
    const [openMainTooltip, setOpenMainTooltip] = useState(false);
    const [getTemplateData, setTemplateData] = useState([]);
    const isInitialMount = useRef(true);
    const [dropdownOpen, setDropdownOpen] = useState({
        networkProviders: false,
        expertTypes: false,
        status: false,
    });
    const dropdownRefs = {
        networkProviders: useRef(null),
        expertTypes: useRef(null),
        status: useRef(null),
    };
    const [crossIconClick, setCrossIconClick] = useState(false);
    const [clickOutside, setClickOutside] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            let isClickInside = false;
            for (const key in dropdownRefs) {
                if (dropdownRefs[key].current && dropdownRefs[key].current.contains(event.target)) {
                    isClickInside = true;
                    break;
                }
            }

            if (!isClickInside || event.target.classList.contains('filtercross--icon') || event.target.classList.contains('header--text') || event.target.classList.contains('header--divText')) {
                setClickOutside(true);
                setDropdownOpen({
                    networkProviders: false,
                    expertTypes: false,
                    status: false,
                });
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: false,
                    status: false,
                });
            }
            else {
                setClickOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [crossIconClick]);

    useEffect(() => {
        dispatch(getTemplatesAPIData(queryData));
    }, []);

    useEffect(() => {
        if (showApprovePopup.open) {
            setShowApprovePopup({ ...showApprovePopup, isDisable: showApproveClicked });
        }
    }, [showApproveClicked]);

    useEffect(() => {
        if (editTemplateData) {
            if (editTemplateData?.templateId) {
                setExpandedData(editTemplateData);
            } else {
                setExpandedRows([]);
                setExpandedData([]);
            }
            setShowApprovePopup({ open: false });
        }
    }, [editTemplateData]);

    useEffect(() => {
        if (loading) {
            return
        }
        setApproveClicked(false);
        let templateResp = commTemplatesData.communicationTemplates;
        if (isInitialMount.current) {
            // Skip the effect on initial mount
            isInitialMount.current = false;
            templateResp = [];
            return;
        }
        let newData = [...data] || [];


        if (!hidecommTemplatesData) {
            if (!commTemplatesData?.lastPage) {
                if (data?.length > 0 && !commTemplatesData?.firstPage) {
                    for (let i = 0; i < templateResp?.length; i++) {
                        newData?.push(templateResp[i]);
                    }
                    setData([...newData]);
                } else {
                    setData(commTemplatesData.communicationTemplates);
                }
            } else {
                for (let i = 0; i < templateResp?.length; i++) {
                    newData?.push(templateResp[i]);
                }
                setData([...newData]);
            }
            setLastPage(commTemplatesData?.lastPage);
            if (commTemplatesData?.commTemplatesData?.length === 0) {
                setHasMore(false);
            }
        }
    }, [commTemplatesData, sortState]);

    useEffect(() => {
        if (queryTemplateId) {
            setExpandedRows([commTemplatesData?.communicationTemplates[0]?.templateId])
        }
    }, [commTemplatesData]);


    useEffect(() => {
        setNetworkProvidersFilters(templateFiltersData?.networkProviders);
        setExpertTypesFilters(templateFiltersData?.expertTypes);
        setStatusFilter(templateFiltersData?.status);
    }, [templateFiltersData]);

    const handleChangeFilter = (column, value) => {
        if (loading) {
            return
        }
        setLastPage(true);
        const newFilters = filters[column]?.includes(value)
            ? filters[column].filter(item => item !== value)
            : [...filters[column], value];

        setFilters({ ...filters, [column]: newFilters });
        let tempFilters = { ...filters, [column]: newFilters }
        let templateQueryData = {
            requestId: queryData,
            templateIds: queryTemplateId ? [queryTemplateId] : null,
            status: tempFilters['status']?.length > 0 ? tempFilters['status'] : null,
            networkProviders: tempFilters['networkProviders']?.length > 0 ? tempFilters['networkProviders'] : null,
            expertTypes: tempFilters['expertTypes']?.length > 0 ? tempFilters['expertTypes'] : null,
        }
        resetExpandedView();
        dispatch(getCommTemplatesAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
    };

    const handleCloseActionPopup = (isCancel, type) => {
        if (isCancel) {
            setExpandedRows([]);
            setExpandedData([]);
            setShowApprovePopup({ open: false });
        } else {
            handleApprove(type);
        }
    }
    useEffect(() => {
        if (approveTemplatesData?.length > 0) {
            dispatch(getTemplatesAPIData(queryData));
            setData([]);
            setShowApprovePopup({ open: false });
            setPage(0);
            let templateQueryData = {
                requestId: queryData,
                templateIds: queryTemplateId ? [queryTemplateId] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            }
            dispatch(getCommTemplatesAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
            dispatch(clearApproveTemplatesAPIData());
        }
        if (error && showApproveClicked === true) {
            toast.error('Approver Designation must be provided to approve a template');
            setShowApprovePopup({ open: false });
            setPage(0);
            setExpandedRows([]);
            setExpandedData([]);
            setSelectedCheckBoxes([]);
            setApproveClicked(false);
        }
    }, [approveTemplatesData, error]);


    const handleSetApproveTemplateData = (type, templatedata) => {
        setShowApprovePopup({
            open: true,
            heading: 'Confirm Approval',
            details: 'Are you sure you want to approve this template? This action will finalize the approval process for this document and cannot be undone. Please confirm to proceed.',
            cancelActionTxt: 'No, Cancel',
            proceedActionTxt: 'Yes, Approve',
            type: 'approve',
            isDisable: showApproveClicked,
            noInputFields: true
        });
        setTemplateData(templatedata);
    }

    const handleApprove = (type) => {
        setApproveClicked(true);
        if (type === 'approveAll') {
            let approveData = [...filteredData];
            const updatedData = approveData?.map(item => ({
                templateId: item?.templateId,
                requestId: item?.requestId,
                networkProvider: item?.networkProvider,
                expertTypes: item?.expertTypes,
                approve: true,
                approverDesignation: approverDesignation,
            }));
            const templateIdCount = updatedData.reduce((acc, item) => {
                acc[item.templateId] = (acc[item.templateId] || 0) + 1;
                return acc;
            }, {});

            // Filter out objects with duplicate templateId
            const uniqueData = updatedData.filter(item => templateIdCount[item.templateId] === 1);
            setShowApproveAll(false);
            dispatch(getApproveTemplatesAPIData(uniqueData));
        }
        else {
            let approveData = [getTemplateData];
            const updatedData = approveData?.map(item => ({
                templateId: item?.templateId,
                requestId: item?.requestId,
                networkProvider: item?.networkProvider,
                expertTypes: item?.expertTypes,
                approve: true,
                approverDesignation: approverDesignation,
            }));
            setShowApproveAll(false);
            dispatch(getApproveTemplatesAPIData(updatedData));
        }
        handleCollapseRows();
    }

    const handleSelectAll = (selectAllParam, selFilter) => {
        if (loading) {
            return
        }
        let temparr = [];
        setData([]);
        setPage(0);
        switch (selectAllParam) {
            case 'extNetworkAll':
                if (filters['networkProviders'].length === selFilter.length) {
                    temparr = [];
                } else {
                    temparr = selFilter?.map(item => item.name);
                }
                setFilters({ ...filters, ['networkProviders']: temparr });
                let templateQueryData = {
                    requestId: queryData,
                    templateIds: queryTemplateId ? [queryTemplateId] : null,
                    status: filters['status']?.length > 0 ? filters['status'] : null,
                    networkProviders: temparr,
                    expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                }
                dispatch(getCommTemplatesAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            case 'expTypeAll':
                if (filters['expertTypes']?.length === selFilter?.length) {
                    temparr = [];
                }
                else {
                    temparr = selFilter?.map(item => item?.name);
                }
                setFilters({ ...filters, ['expertTypes']: temparr });
                let templateQueryData2 = {
                    requestId: queryData,
                    templateIds: queryTemplateId ? [queryTemplateId] : null,
                    status: filters['status']?.length > 0 ? filters['status'] : null,
                    networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                    expertTypes: temparr
                }
                dispatch(getCommTemplatesAPIData(templateQueryData2, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            case 'statusAll':
                if (filters['status']?.length === selFilter?.length) {
                    temparr = [];
                }
                else {
                    temparr = selFilter?.map(item => item?.name);
                }
                setFilters({ ...filters, ['status']: temparr });
                let templateQueryData3 = {
                    requestId: queryData,
                    templateIds: queryTemplateId ? [queryTemplateId] : null,
                    status: temparr,
                    networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                    expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                }
                dispatch(getCommTemplatesAPIData(templateQueryData3, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
                break;

            default:
                break;
        }
    }

    const handleClearFilter = (column) => {
        setFilters({ ...filters, [column]: [] });
        setDropdownOpen({
            networkProviders: false,
            expertTypes: false,
            status: false,
        });
        let templateQueryData = {
            requestId: queryData,
            templateIds: queryTemplateId ? [queryTemplateId] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
        }
        if (column === 'status') {
            templateQueryData.status = null;
        }
        else if (column === 'networkProviders') {
            templateQueryData.networkProviders = null;
        }
        else if (column === 'expertTypes') {
            templateQueryData.expertTypes = null;
        }
        resetExpandedView();
        dispatch(getCommTemplatesAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        toggleCrossIcon(column, false);
    };

    const resetExpandedView = () => {
        setPage(0);
        setData([]);
        setExpandedRows([]);
        setExpandedData([]);
        setShowApprovePopup({ open: false });
    }

    const handleCrossIconClick = (type) => {
        setDropdownOpen({
            networkProviders: false,
            expertTypes: false,
            status: false,
        });
        setCrossIconClick(true);
        toggleCrossIcon(type, false);
    };

    const toggleCrossIcon = (column, flag) => {
        switch (column) {
            case 'status':
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: false,
                    [column]: flag,
                });
                break;

            case 'networkProviders':
                setShowCrossIcon({
                    [column]: flag,
                    expertTypes: false,
                    status: false,
                });
                break;

            case 'expertTypes':
                setShowCrossIcon({
                    networkProviders: false,
                    status: false,
                    [column]: flag,
                });
                break;
        }
    };


    const uniqueValuesForColumn = (column) => {
        switch (column) {
            case 'networkProviders':
                return networkProvidersFilters;
            case 'status':
                return statusFilter;
            case 'expertTypes':
                return expertTypesFilters;
        }
    };

    const toggleRowExpansion = (id) => {
        let temp = [];
        if (expandedRows?.includes(id)) {
            temp = expandedRows?.filter(row => row !== id);
        } else {
            temp = [...expandedRows, id];
        }
        setExpandedRows(temp);
    };

    const handleCollapseRows = () => {
        setExpandedRows([]);
    }

    const handleExpandAllRows = () => {
        setExpandedRows(data?.map(item => item?.templateId));
    }

    const loadMoreData = () => {
        if (lastPage === false) {
            setPage(prevPage => prevPage + 1);
            fetchData(page + 1);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [hidecommTemplatesData]);

    const fetchData = async (page) => {
        if (!hidecommTemplatesData) {
            let templateQueryData = {
                requestId: queryData,
                templateIds: queryTemplateId ? [queryTemplateId] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null
            }
            dispatch(getCommTemplatesAPIData(templateQueryData, page));
        }
    };

    const handleCheckboxDisabled = (item) => {
        if (item?.status === 'Awaiting Template' || item?.status === 'Approved') {
            return true;
        }
        else {
            return false
        }
    }

    const handleCheckBoxChange = (event, inputData) => {
        let updatedSelectedCheckBoxes;
        if (inputData.checked) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, inputData?.label?.templateId]
            setSelectedCheckBoxes(prev => [...prev, inputData?.label?.templateId]);
        } else {
            updatedSelectedCheckBoxes = selectedCheckBoxes.filter(item => item !== inputData?.label?.templateId);
            setSelectedCheckBoxes(prev => prev.filter(item => item !== inputData?.label?.templateId));
        }

        let tempdata = data?.filter((item => item?.templateId === inputData?.label?.templateId));
        let arr = [...filteredData];
        arr?.push(tempdata[0]);
        setFilteredData(arr);
        if (data?.length === updatedSelectedCheckBoxes?.length) {
            setShowApproveAll(true);
        }
        else if (updatedSelectedCheckBoxes?.length > 1) {
            setShowApproveAll(true);
        }
        else {
            setShowApproveAll(false);
        }
        setOpenMainTooltip(false);
    }

    const handleAllCheckChange = () => {
        if (!noRecFound) {
            let temp = [];
            let tempData = data?.filter(((itemTemp) => itemTemp?.status === 'Awaiting Approval'));
            if (selectedCheckBoxes?.length === tempData?.length) {
                setSelectedCheckBoxes([]);
                setShowApproveAll(false);
            }
            else {
                tempData?.map((item) => {
                    if (item?.status === 'Awaiting Approval') {
                        temp?.push(item?.templateId);
                    }
                });
                setShowApproveAll(true);
            }
            setFilteredData(tempData);
            setSelectedCheckBoxes(temp);
        }
    }

    const handleSort = (flag) => {
        if (loading) {
            return
        }
        setLastPage(true);
        let templateQueryData = {
            requestId: queryData,
            templateIds: queryTemplateId ? [queryTemplateId] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
        }
        resetExpandedView();
        dispatch(getCommTemplatesAPIData(templateQueryData, 0, flag === true ? 'Newest' : 'Oldest'));
        setSortState(flag);
    };

    const handleshowApproveAll = (flag) => {
        setShowApproveAll(flag);
    }

    const handleCancel = () => {
        setShowApproveAll(selectedCheckBoxes?.length === data?.length && data?.length > 0);
        setExpandedRows([]);
        setExpandedData([]);
        setSelectedCheckBoxes([]);
    }

    const handleTimeStamp = (item) => {
        if (item?.status === 'Awaiting Template') {
            return 'NA'
        }
        else {
            return formatDate(item?.receivedTimestamp)
        }
    }

    return (
        <>
            <div className='commTemplates__wrapper'>
                <div className='commTemplates__note'><Image src={WarningIcon} className='warning--img' alt="WarningIcon" />Note: Only Principals and more senior cohorts are authorized to approve communication templates.</div>
                <div className='templatecount__expCollWrap'>
                    <div className='grid-header-count'>
                        <div className='count--badge'>{commTemplatesData?.totalTemplates}</div>
                        Templates
                    </div>
                    <div className='collapse__approveWrap'>
                        {
                            showApproveAll === true &&
                            <div className='shareApprove__template'>
                                <div className='share__template'>
                                    <button className={approvalLoading ? 'share--template outline-btn disabled--btn' : 'share--template outline-btn'}
                                        disabled={approvalLoading}
                                        onClick={() => {
                                            setOpenMainTooltip(!openMainTooltip);
                                            approverRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                            setSelectedAllApprover('');
                                        }}>
                                        <Image src={ShareTemplateIcon} className='edit--icon' alt="ShareTemplateIcon" />
                                        <span className='disable-btn'>Share via Email for Approval</span>
                                    </button>
                                    {
                                        openMainTooltip &&
                                        <CustomTooltip ref={approverRef} open={openMainTooltip} selectedApprover={selectedAllApprover} handleClosePopup={(value) => { setOpenMainTooltip(value) }} handleCancel={handleCancel} selectedCheckBoxes={selectedCheckBoxes} data={data} isTemplate={false} />
                                    }
                                </div>
                                {
                                    // (approverDesignation?.includes('Outside Consultant') || approverDesignation?.includes('Principal') || approverDesignation?.includes('Partners') || approverDesignation?.includes('Associate Director') || approverDesignation?.includes('Managing Director')) &&
                                    <div className='approve__template'>
                                        <button className='approve--template' onClick={() =>
                                            setShowApprovePopup({
                                                open: true,
                                                heading: 'Confirm Bulk Approval',
                                                details: 'Are you sure you want to approve all selected templates? This action will finalize the approval process for these documents and cannot be undone. Please confirm to proceed.',
                                                cancelActionTxt: 'No, Cancel',
                                                proceedActionTxt: 'Yes, Approve All',
                                                type: 'approveAll',
                                                isDisable: showApproveClicked,
                                                noInputFields: true
                                            })} >
                                            <Image src={ApproveTemplateIcon} className='edit--icon' alt="ApproveTemplateIcon" />Approve All</button>
                                    </div>

                                }
                            </div>
                        }
                        <div className='expCollapse__wrap'>
                            {
                                expandedRows?.length > 0 &&
                                <div className='collapse--text' onClick={() => handleCollapseRows()}>
                                    <Image src={CollapseIcon} className='collapse--img' alt="CollapseIcon" />
                                    Collapse All
                                </div>
                            }
                            {
                                expandedRows?.length == 0 && data?.length > 0 &&
                                <div className='expand--text' onClick={() => handleExpandAllRows()}>
                                    <Image src={ExpandIcon} className='collapse--img' alt="ExpandIcon" />
                                    Expand All</div>
                            }
                        </div>
                    </div>
                </div>

                <Table sortable celled className='calls--table'>
                    <Table.Header className='completedcalls__header'>
                        <Table.Row>
                            <Table.HeaderCell className='comm-header--sorttitle completedcalls__header-border'>
                                <Checkbox onChange={handleAllCheckChange} checked={data?.filter((item) => item?.status === 'Awaiting Approval')?.length === selectedCheckBoxes?.length && data?.length > 0 && selectedCheckBoxes?.length > 0}
                                    // disabled={!approverDesignation?.includes('Outside Consultant') && !approverDesignation?.includes('Principal') && !approverDesignation?.includes('Partners') && !approverDesignation?.includes('Associate Director') && !approverDesignation?.includes('Managing Director')}
                                    className={noRecFound ? 'een-checkbox tableAll--check no--pointer' : 'een-checkbox tableAll--check'} />
                            </Table.HeaderCell>
                            <Table.HeaderCell className='exp-header--title req--idtitle completedcalls__header-border'>
                                <div className='header--div'>
                                    <div className='header--divText'>
                                        Request ID
                                    </div>
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                <div className='header--div' ref={dropdownRefs.networkProviders}>
                                    <div className='header--divText'>
                                        <span className='header--text'>
                                            External Expert Network
                                        </span>
                                    </div>
                                    {
                                        !queryTemplateId &&
                                        <div className='filter--countwrap'>
                                            {
                                                filters?.networkProviders?.length > 0 &&
                                                <span className='filter--count'>{filters?.networkProviders?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('networkProviders')} /></span>
                                            }
                                            <Dropdown
                                                open={dropdownOpen['networkProviders']}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (!clickOutside) {
                                                        setDropdownOpen({
                                                            networkProviders: true,
                                                            expertTypes: false,
                                                            status: false,
                                                        });
                                                        setShowCrossIcon({
                                                            'networkProviders': true,
                                                            'expertTypes': false,
                                                            'status': false
                                                        })
                                                    }
                                                    else {
                                                        setClickOutside(false);
                                                    }
                                                }}
                                                closeOnChange={false}
                                                icon={<Image src={showCrossIcon['networkProviders'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['networkProviders'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleCrossIconClick('networkProviders');
                                                    }
                                                    }
                                                />}
                                                floating
                                                labeled
                                                button
                                                className="icon"
                                            >
                                                <Dropdown.Menu className={networkProvidersFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                    {
                                                        networkProvidersFilters?.length > 0 ?
                                                            <>
                                                                <>
                                                                    <Dropdown.Item key={-1} onClick={() => handleSelectAll('extNetworkAll', networkProvidersFilters)}
                                                                        className='filter--item'>
                                                                        <div className='filter__chk'>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={filters['networkProviders']?.length === networkProvidersFilters?.length}
                                                                                className='filter--checkBox'
                                                                            />
                                                                            <div className={filters['networkProviders']?.length === networkProvidersFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider className='drop--divider' />
                                                                </>
                                                                {networkProvidersFilters?.map((value, index) => (
                                                                    <>
                                                                        <Dropdown.Item
                                                                            // key={index} 
                                                                            key={value?.name || value?.id || value?.count}
                                                                            onClick={() => {
                                                                                handleChangeFilter('networkProviders', value?.name)
                                                                            }
                                                                            }
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['networkProviders']?.includes(value?.name)}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['networkProviders']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                            </div>
                                                                            <div className='filter--countText'>{value?.count}</div>
                                                                        </Dropdown.Item>
                                                                        <>{index !== uniqueValuesForColumn('networkProviders')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                    </>
                                                                ))}
                                                            </>
                                                            :
                                                            <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                <div className='header--div' ref={dropdownRefs.expertTypes}>
                                    <div className='header--divText'>
                                        <span className='header--text'>
                                            Expert Types/Modules
                                        </span>
                                    </div>
                                    {
                                        !queryTemplateId &&
                                        <div className='filter--countwrap'>
                                            {
                                                filters?.expertTypes?.length > 0 &&
                                                <span className='filter--count'>{filters?.expertTypes?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertTypes')} /></span>
                                            }
                                            <Dropdown
                                                open={dropdownOpen['expertTypes']}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (!clickOutside) {
                                                        setDropdownOpen({
                                                            networkProviders: false,
                                                            expertTypes: true,
                                                            status: false,
                                                        });
                                                        setShowCrossIcon({
                                                            'networkProviders': false,
                                                            'expertTypes': true,
                                                            'status': false
                                                        })
                                                    }
                                                    else {
                                                        setClickOutside(false);
                                                    }
                                                }}
                                                icon={<Image src={showCrossIcon['expertTypes'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['expertTypes'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleCrossIconClick('expertTypes');
                                                    }}
                                                />}
                                                floating
                                                labeled
                                                button
                                                className="icon"
                                            >
                                                <Dropdown.Menu className={expertTypesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>
                                                    {
                                                        expertTypesFilters?.length > 0 ?
                                                            <><>
                                                                <Dropdown.Item key={-1} onClick={() => handleSelectAll('expTypeAll', expertTypesFilters)}
                                                                    className='filter--item'>
                                                                    <div className='filter__chk'>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={filters['expertTypes']?.length === expertTypesFilters?.length}
                                                                            className='filter--checkBox' />
                                                                        <div className={filters['expertTypes']?.length === expertTypesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider className='drop--divider' />
                                                            </><>
                                                                    {expertTypesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={index} onClick={() => handleChangeFilter('expertTypes', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['expertTypes']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['expertTypes']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('expertTypes')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </></>
                                                            :
                                                            <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                <div className='header--div'>
                                    <div className='header--divText'>
                                        Received On
                                    </div>
                                    {
                                        !queryTemplateId &&
                                        <Image src={sortState === null ? SortIcon : (sortState === true ? SortAsc : SortDesc)} alt="icon" className='filter--icon'
                                            onClick={() => handleSort(!sortState)}
                                        />
                                    }
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                <div className='header--div' ref={dropdownRefs?.status}>
                                    <div className='header--divText'>
                                        Status
                                    </div>
                                    {
                                        !queryTemplateId &&
                                        <div className='filter--countwrap'>
                                            {
                                                filters?.status?.length > 0 &&
                                                <span className='filter--count statuscount'>{filters?.status?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('status')} /></span>
                                            }
                                            <Dropdown
                                                open={dropdownOpen['status']}
                                                onClick={() => {
                                                    event.stopPropagation();
                                                    if (!clickOutside) {
                                                        setDropdownOpen({
                                                            networkProviders: false,
                                                            expertTypes: false,
                                                            status: true,
                                                        });
                                                        setShowCrossIcon({
                                                            'networkProviders': false,
                                                            'expertTypes': false,
                                                            'status': true
                                                        })
                                                    }
                                                    else {
                                                        setClickOutside(false);
                                                    }
                                                }}
                                                icon={<Image src={showCrossIcon['status'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['status'] ? 'filter--icon filtercross--icon' : 'filter--icon'} onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleCrossIconClick('status');
                                                }} />}
                                                floating
                                                labeled
                                                button
                                                className="icon"
                                            >
                                                <Dropdown.Menu className={statusFilter?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>
                                                    {
                                                        statusFilter?.length > 0 ?
                                                            <>
                                                                <>
                                                                    <Dropdown.Item key={-1} onClick={() => handleSelectAll('statusAll', statusFilter)}
                                                                        className='filter--item'>
                                                                        <div className='filter__chk'>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={filters['status']?.length === statusFilter?.length}
                                                                                className='filter--checkBox' />
                                                                            <div className={filters['status']?.length === statusFilter?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider className='drop--divider' />
                                                                </>
                                                                {statusFilter?.map((value, index) => (
                                                                    <>
                                                                        <Dropdown.Item key={index} onClick={() => handleChangeFilter('status', value?.name)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['status']?.includes(value?.name)}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['status']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name?.length > 17 ? value?.name?.substring(0, 17)?.concat('...') : value?.name}</div>
                                                                            </div>
                                                                            <div className='filter--countText'>{value?.count}</div>
                                                                        </Dropdown.Item>
                                                                        <>{index !== uniqueValuesForColumn('status')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                    </>
                                                                ))}
                                                            </>
                                                            :
                                                            <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className='comm-header--sorttitle'>
                                <div className='header--div last--col'>
                                    <div className='header--divText'>

                                    </div>
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>
                <div id="scrollable-container">
                    <InfiniteScroll
                        dataLength={data?.length || 0}
                        hasMore={hasMore}
                        scrollableTarget="scrollable-container"
                        next={loadMoreData}
                        className='commTemplates__infiniteScroll'
                    >
                        <Table sortable celled className='calls--table'>
                            <Table.Body className='completedcalls__body'>
                                {loading ? (
                                    <div className='loading--center'>
                                        <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                                    </div>
                                ) :
                                    (
                                        (noRecFound && !loading) ?
                                            <div className='noRecFound'>
                                                {filters['status']?.length === 0 && filters['networkProviders']?.length === 0 && filters['expertTypes']?.length === 0
                                                    ? 'Currently, there are no templates waiting for approval.'
                                                    : 'No communication templates available for the selected filters.'}
                                            </div>
                                            : (
                                                data?.length > 0 &&
                                                data?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Table.Row key={index} className='table--row'>
                                                            <Table.Cell className='table-cell tablebody--check'>
                                                                <Checkbox
                                                                    label={item}
                                                                    onChange={handleCheckBoxChange}
                                                                    checked={selectedCheckBoxes?.includes(item?.templateId) && item?.status === 'Awaiting Approval'}
                                                                    className='table--check een-checkbox'
                                                                    disabled={handleCheckboxDisabled(item)}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell className='user--name table--cell req--id fixed-column' onClick={() => toggleRowExpansion(item?.templateId)}>
                                                                ID: {item?.requestId}
                                                            </Table.Cell>
                                                            <Table.Cell className='user--data table--cell' onClick={() => toggleRowExpansion(item?.templateId)}>{item?.networkProvider || "NA"}</Table.Cell>
                                                            <Table.Cell className='user--data time--field table--cell' onClick={() => toggleRowExpansion(item?.templateId)}>
                                                                {(item?.expertTypes)?.length > 0 ? item?.expertTypes.join(', ') : "NA"}
                                                            </Table.Cell>
                                                            <Table.Cell className='user--data time--field table--cell' onClick={() => toggleRowExpansion(item?.templateId)}>{handleTimeStamp(item)}</Table.Cell>
                                                            <Table.Cell className='user--data time--field table--cell' onClick={() => toggleRowExpansion(item?.templateId)}>{item?.status || "NA"}</Table.Cell>
                                                            <Table.Cell className='user--datalast table--cell' onClick={() => toggleRowExpansion(item?.templateId)}>
                                                                <Image
                                                                    src={expandedRows?.includes(item?.templateId) ? TopIcon : RightIcon}
                                                                    alt="RightIcon"
                                                                    className='right--icon'
                                                                    onClick={() => toggleRowExpansion(item?.templateId)}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        {expandedRows?.includes(item?.templateId) && (
                                                            <Table.Row className='template--div'>
                                                                <Table.Cell colSpan={7} className='template--cellwrap'>
                                                                    <div className='template--cell'>
                                                                        <Template
                                                                            handleshowApproveAll={handleshowApproveAll}
                                                                            handleCancel={handleCancel}
                                                                            status={item?.status}
                                                                            templatedata={item?.template}
                                                                            templateDetails={item}
                                                                            expandedData={expandedData}
                                                                            id={item?.templateId}
                                                                            key={item?.templateId}
                                                                            handleApproveTemplate={(type, item) => handleSetApproveTemplateData(type, item)}
                                                                            empDes={approverDesignation}
                                                                            approvalLoading={approvalLoading}
                                                                        />
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            )
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
            {
                showApprovePopup.open &&
                <ActionAlertWindow props={showApprovePopup} closeAlertWindowHandler={handleCloseActionPopup}>
                    {showApprovePopup.type == "approve" && <>
                        <div className="approveTemplates__bodySubText"><Image src={ApproveCheck} alt="ApproveCheck" /> I Confirm I belong to one of these cohorts:</div>
                        <div className="approveTemplates__designation">Principal, Partner, Partner and Associate Director, MDP or MSDP.</div>
                    </>}
                </ActionAlertWindow>
            }
        </>
    )
}